import React, { useState, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { updateGlobalScrollLock, updateLang } from "../../Store/action";

// Header comps
import { Logo } from "./Logo";
import { Burger } from "./Burger";
import { LanguageSwitch } from "./LanguageSwitch";
import { Menu } from "./Menu";

// Outer comps
import { SearchBar } from "../SearchBar";
import { Banners } from "../Banners";
import { SocialNetworks } from "../SocialNetworks";
import { Live } from "../Pushes/Live";
import { Modal } from "../Modal";
import { Newsletter } from "../Newsletter";

import LiveLogo from "../../Assets/img/live.svg";
import { updateGlobalStoreEvent } from "../../Store/action";
import ReactGA from "react-ga";

// Hooks
import { useApi } from "../../Hooks/useApi";
import { useToggle } from "../../Hooks/useToggle";
import { useSelector, useDispatch } from "react-redux";

export const Header = React.memo(() => {
  // STORE REDUX
  let { lang } = useSelector((state) => state.lang);
  let { global, lockScroll, ga } = useSelector((state) => state.global);
  let { sticky } = useSelector((state) => state.header);
  let { page } = useSelector((state) => state.page);
  let { mobile, desktop, desktopWide } = useSelector(
    (state) => state.breakpoint
  );
  let dispatch = useDispatch();

  // hooks
  let refContainer = useRef();
  let menuRef = useRef();
  let menuContainerRef = useRef();
  let upperRef = useRef();
  let bannerRef = useRef();
  let [glass, toggleGlass] = useState(false);
  let [modalNLOpen, toggleNLModal] = useState(false);
  let [modalLiveOpen, toggleLiveModal] = useState(false);
  let [searchBarOpen, toggleSearchBar] = useToggle(false);
  let [burgerOpen, toggleBurger] = useState(false);
  let [menuOpen, toggleMenu] = useState(false);

  // destructuring
  let { header, social_networks, banners, bloc_newsletter } = global;

  // Banners
  let [dataBanners, dataBannersIsLoaded] = useApi({
    name: banners.type.event,
    isBanner: true,
  });
  useEffect(() => {
    dispatch(updateGlobalStoreEvent(dataBanners));
  }, [dispatch, dataBanners]);

  // Scroll lock
  useEffect(() => {
    dispatch(updateGlobalScrollLock(false));
  }, [dispatch, global]);

  // Scroll Handler
  useEffect(() => {
    const handleScroll = () => {
      if (refContainer.current) {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (!sticky && !mobile) {
          toggleMenu(false);
          toggleBurger(false);
          toggleGlass(false);

          upperRef.current.style.transform = "none";
        }

        if (st > 0 && !sticky) {
          dispatch({ type: "STICKY", value: true });
        } else if (st <= 0 && sticky) {
          dispatch({ type: "STICKY", value: false });
        }
      }
    };
    if (!modalLiveOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    // clean listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky, refContainer, dispatch, modalLiveOpen, mobile]);

  // desktop only
  useEffect(() => {
    if (desktop || desktopWide) {
      toggleBurger();
      //enableBodyScroll(refContainer)
    }
  }, [desktop, desktopWide, refContainer]);

  // Open burger
  useEffect(() => {
    if (!desktop || !desktopWide) {
      burgerOpen
        ? disableBodyScroll(menuRef.current)
        : enableBodyScroll(menuRef.current);
    }
  }, [burgerOpen, desktop, desktopWide, menuRef]);

  // style toggle burger and menu desktop
  useEffect(() => {
    if (
      menuContainerRef &&
      menuContainerRef.current &&
      bannerRef &&
      bannerRef.current &&
      upperRef &&
      upperRef.current
    ) {
      let value =
        menuContainerRef.current.clientHeight +
        bannerRef.current.clientHeight +
        upperRef.current.clientHeight;
      if (desktop || desktopWide) {
        if (burgerOpen) {
          upperRef.current.style.transform = `translateY(${value}px)`;
        } else {
          upperRef.current.style.transform = "none";
        }

        if (menuOpen) {
          menuContainerRef.current.style.maxHeight = `${menuContainerRef.current.scrollHeight}px`;

          if (burgerOpen) {
            setTimeout(() => {
              upperRef.current.style.transform = `translateY(${
                menuContainerRef.current.clientHeight +
                bannerRef.current.clientHeight +
                upperRef.current.clientHeight
              }px)`;
            }, 400);
          }
        } else {
          menuContainerRef.current.style.maxHeight = "45px";
          if (burgerOpen) {
            setTimeout(() => {
              upperRef.current.style.transform = `translateY(${
                menuContainerRef.current.clientHeight +
                bannerRef.current.clientHeight +
                upperRef.current.clientHeight
              }px)`;
            }, 400);
          }
        }
      }
    }
  }, [
    desktop,
    desktopWide,
    burgerOpen,
    menuOpen,
    upperRef,
    bannerRef,
    menuContainerRef,
  ]);

  // custom funcs
  const closeMenu = () => {
    toggleMenu(!menuOpen);
  };
  const closeBurger = () => {
    toggleBurger(!burgerOpen);
  };
  const closeLiveModal = () => {
    toggleLiveModal(!modalLiveOpen);
    dispatch(updateGlobalScrollLock(!lockScroll));
  };
  const closeNLModal = () => {
    toggleNLModal(!modalNLOpen);
    dispatch(updateGlobalScrollLock(!lockScroll));
  };
  const closeGlass = () => {
    toggleGlass(!glass);
  };

  const onClickEvent = () => {
    if (ga) {
      ReactGA.event({
        category: "Newsletter",
        action: "Click",
        label: header.newsletter.title,
      });
    }
  };
  return (
    <>
      {dataBannersIsLoaded ? (
        <header
          className="header"
          ref={refContainer}
          data-header-open={burgerOpen ? "true" : "false"}
          data-header-sticky={sticky ? "true" : "false"}
          data-menu-open={menuOpen ? "true" : "false"}>
          <div ref={bannerRef}>
            <Banners {...dataBanners} name="event" />
          </div>

          <div className="header-wrapper">
            <div className="header-upper" ref={upperRef}>
              <div className="container">
                <div className="left-elements hidden-l hidden-xl">
                  <div className="mobile-toggle">
                    <Burger
                      closeBurger={closeBurger}
                      modalNLOpen={modalNLOpen}
                      toggleLiveModal={toggleLiveModal}
                      toggleNLModal={toggleNLModal}
                    />
                  </div>
                  <button
                    className="live"
                    onClick={() => {
                      closeLiveModal();
                      toggleBurger(false);
                    }}>
                    <img src={LiveLogo} alt="Live" />
                    <span>Live</span>
                  </button>
                  <Modal
                    closeLiveModal={closeLiveModal}
                    modalLiveOpen={modalLiveOpen}
                    closeBurger={closeBurger}
                    target={refContainer.current}
                    mobile={mobile}
                    component="live-modal">
                    <Live
                      modalLiveOpen={modalLiveOpen}
                      closeLiveModal={closeLiveModal}
                    />
                  </Modal>
                </div>

                <Logo lang={lang} logo={header.logo} page={page} />

                <div className="header-extra">
                  <div className="header-extra-wrapper">
                    <div className="header-social-networks hidden-s hidden-m">
                      <SocialNetworks {...social_networks} />
                    </div>

                    <LanguageSwitch
                      updateLang={updateLang}
                      datas={header.language}
                    />
                  </div>
                  {bloc_newsletter && (
                    <>
                      <button
                        className="newsletter-text hidden-s hidden-m"
                        onClick={() => {
                          closeNLModal();
                          onClickEvent();
                          burgerOpen && closeBurger();
                          closeGlass();
                        }}>
                        {header.newsletter.title}
                        <i className="icon icon-right-chevron"></i>
                      </button>

                      <Modal
                        modalNLOpen={modalNLOpen}
                        closeNLModal={closeNLModal}
                        closeBurger={closeBurger}
                        toggleGlass={toggleGlass}
                        target={refContainer.current}>
                        <Newsletter {...global} />
                      </Modal>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="header-menu" ref={menuRef}>
              <div className="container">
                <Logo lang={lang} logo={header.logo} />

                <div className="right-elements">
                  <div
                    className="searchbar-container"
                    data-searchbar-open={searchBarOpen ? "true" : "false"}>
                    <SearchBar
                      mobile={mobile}
                      closeMenu={closeMenu}
                      closeBurger={closeBurger}
                      toggleSearchBar={toggleSearchBar}
                      page={page}
                    />
                  </div>
                  <div className="desktop-toggle hidden-s hidden-m">
                    <Burger
                      closeBurger={closeBurger}
                      glass={glass}
                      toggleGlass={toggleGlass}
                    />
                  </div>
                </div>

                <Menu
                  items={header.links}
                  mobile={mobile}
                  desktop={desktop}
                  desktopWide={desktopWide}
                  menuOpen={menuOpen}
                  toggleBurger={toggleBurger}
                  closeMenu={closeMenu}
                  menuContainerRef={menuContainerRef}
                />

                <div className="header-social-networks hidden-l hidden-xl">
                  <SocialNetworks {...social_networks} />
                </div>

                {bloc_newsletter && (
                  <button
                    className="newsletter-text hidden-l hidden-xl"
                    onClick={() => {
                      closeNLModal();
                      onClickEvent();
                      if (mobile) {
                        burgerOpen && closeBurger();
                      }
                    }}>
                    {header.newsletter.title}
                    <i className="icon icon-right-chevron"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
});
