import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

// Components
import { Metatags } from "../Components/Metatags";
import { PushLayout } from "../Components/Pushes/PushLayout";
import { Banners } from "../Components/Banners";
import { SkeletonPage } from "../Components/Skeletons/SkeletonPage";

// External lib
import ReactGA from "react-ga";

// Hooks
import { useApi } from "../Hooks/useApi";

export const TemplateRubrique = ({ _uid, slug }) => {
  let { global, ga } = useSelector((state) => state.global);
  let { mobile } = useSelector((state) => state.breakpoint);
  let [numberOfArticles, setNumberOfArticles] = useState(11);
  let [dataNews, dataNewsIsLoaded] = useApi({
    name: "news",
    display: numberOfArticles,
    categories: slug,
  });
  let { metas, microdonnees, seo_link } = dataNews;

  let history = useHistory();
  let { pathname, hash } = useLocation();
  let url = pathname.split("/").pop();

  let [dataBanners, dataBannersIsLoaded] = useApi({
    name: `${global.banners.rubriques[slug]}`,
    isBanner: true,
  });

  let isLoaded = dataNewsIsLoaded && dataBannersIsLoaded;
  let pageRef = useRef();
  let dispatch = useDispatch();

  // GA
  // useEffect(() => {
  // 	if (isLoaded && ga) {
  // 		ReactGA.set({
  // 			"@context": "https://schema.org",
  // 			"@type": "Category",
  // 			"title": microdonnees.category.title,
  // 			"url": microdonnees.category.url
  // 		})
  // 	}
  // }, [slug, isLoaded, microdonnees, ga])

  const [microdatas, setMicrodatas] = useState({});
  useEffect(() => {
    if (isLoaded) {
      setMicrodatas({
        "@context": "https://schema.org",
        "@type": "Category",
        title: microdonnees.category.title,
        url: microdonnees.category.url,
      });
    }
  }, [isLoaded]);

  // Dispatch
  useEffect(() => {
    dispatch({ type: "pageslug", value: `rubrique-${slug}` });
  }, [dispatch, global.banners, slug, isLoaded]);

  useEffect(() => {
    if (mobile) {
      setNumberOfArticles(8);
    }
  }, [mobile]);
  // replace url
  useEffect(() => {
    if (!url) history.replace({ pathname: pathname.replace(/\/$/, "") });
  }, [hash, history, pathname, url]);

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...seo_link} {...metas} microdatas={microdatas} />
          <div className="page" ref={pageRef}>
            <Banners {...dataBanners} name="billboard" />
            <div className="content">
              <div className="container">
                <div className="rubrique">
                  <PushLayout
                    dataNews={dataNews}
                    dataBanners={dataBanners}
                    slug={slug}
                    _uid={_uid}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SkeletonPage />
      )}
    </>
  );
};
