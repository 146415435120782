import React from "react";

export const Alert = (props) => {
  const { alert = [] } = props;

  return (
    <>
      {alert.length > 0 ? (
        <div className="alert">
          <i className="icon icon-alerte"></i>
          <div className="text">
            <div className="anim">
              {alert.map((block) => (
                <p key={block.text}>{block.text}</p>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
