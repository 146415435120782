import React, { useState, useEffect } from "react";

export const Filter = ({
  cat,
  setOpen,
  current,
  title,
  displayed,
  choices,
  handleInputChange,
}) => {
  let [expandabled, setExpandabled] = useState(false);

  // select states
  let [state, setState] = useState({
    active: false,
    choices: choices.map(({ name, slug }) => ({
      value: slug,
      label: name,
      category: cat,
      isChecked: false,
    })),
  });

  const handleToggle = () => {
    current === cat ? setOpen(null) : setOpen(cat);
  };

  const handleChange = (e) => {
    let itemValue = e.target.value;
    let checked = e.target.checked;

    let list = state.choices.map((item) =>
      item.value === itemValue ? { ...item, isChecked: checked } : item
    );

    setState({
      ...state,
      active: true,
      choices: list,
    });
  };

  useEffect(() => {
    if (state.choices.length > 0) {
      handleInputChange(state);
    }
  }, [state]);

  useEffect(() => {
    current === cat ? setExpandabled(true) : setExpandabled(false);
  }, [current, cat]);

  return (
    displayed && (
      <div
        className="select"
        data-list-open={expandabled}
        data-active={state.active}
      >
        <button className="select-btn" onClick={handleToggle}>
          <span className="txt">{title}</span>
          <span className={`${expandabled ? "icon-down" : "icon-up"}`}></span>
        </button>
        <div className="select-list">
          {choices &&
            choices.map((item, index) => (
              <div className="select-item" key={item.slug}>
                <input
                  type="checkbox"
                  value={item.slug}
                  name={item.name}
                  cat={item.cat}
                  id={item.slug}
                  data-id={index}
                  checked={item.sChecked}
                  onChange={handleChange}
                />
                <label htmlFor={item.slug}>
                  {item.name}&nbsp;<sup>{item.count}</sup>
                </label>
              </div>
            ))}
        </div>
      </div>
    )
  );
};
