import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player/youtube';
import { Wysiwyg } from '../Components/Wysiwyg';
import { Fade } from 'react-awesome-reveal';

export const Video = ({...props}) => {
	
	let [showVideo, setShowVideo ] = useState(false);
	let [youtubeRGPD, setYoutubeRGPD ] = useState(window.tarteaucitron.state.youtubeapi);
	const { title, cover, url, type_video } = props.datas;

	const videoSettings = {
        youtube: {
            playerVars: { showinfo: 0 }
        }
	}

	const handleVideo = () =>{
		setShowVideo(true);
	}
	
	useEffect(()=>{
		let block = document.querySelector('.video_cookie');
		if(block){
			block.onclick = function(){
				setYoutubeRGPD(true)
				window.tarteaucitron.userInterface.respond(block.querySelector('.tarteaucitronAllow'), true) // surcharge de tarteaucitron.engage
			}
		}
	},[showVideo])

	return(
		<Fade direction="top" triggerOnce className="video_comp">
			<>
				<div className="video">
					{cover.url ?
						showVideo === false ?
							<div className="video_thumbnail" style={{backgroundImage : `url(${cover.url})`}} onClick={handleVideo} >
								{ 
									title &&
									<div className="video_title">
										<h3>{title}</h3>
									</div>
								}
								<div className="video_button"></div>
							</div> 
						:
						showVideo === true && 
							type_video === 'video_file' ?
								<video controls autoPlay className='video_player'>
									<source src={url} type="video/mp4" />
								
									Sorry, your browser doesn't support embedded videos.
								</video>
						:		
						youtubeRGPD ?
							<ReactPlayer 	
								className='video_player' 
								config={ videoSettings } 
								url={url} 
								title={title && title} 
								onUnstarted={true}
								width='100%'
								height='100%'
								playIcon={<ButtonPlayVideo/>}
								playing={true}
								/>
							:
							<div className="video_cookie">
								<div>
									<Wysiwyg >{window.tarteaucitron.engage("youtubeapi")}</Wysiwyg>
								</div>
							</div>	
						: 
						<video controls className='video_player'>
							<source src={url} type="video/mp4" />
						
							Sorry, your browser doesn't support embedded videos.
						</video>
					}			
				</div>
				{
					title && 
					<div className="video_title_mobile">
						{title}
					</div>
				}
			</>
		</Fade>
	)
}

const ButtonPlayVideo = () =>{
	return(
		<div className="video_button"></div>
	)
}