import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";

/**
 * @async Creation d'un hook d'appel des API
 * @param {sting} name nom de l'API
 * @param {sting} _uid ID du template
 * @param {sting} slug nom d'article
 * @param {sting} termOfSearch terme à rechercher ?s=
 * @returns {[Array, Boolean]} data Datafetch = [ JSON], isLoaded = false]
 */

export const useApi = ({
  name = "",
  slug,
  _uid,
  termOfSearch,
  categories,
  isBanner,
  isRoute,
  isSocial,
  isNewsletter,
  display,
  delay,
  light,
  page,
  offerParams,
}) => {
  const { lang } = useSelector((state) => state.lang);

  const [dataFetch, setDataFetch] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const prevData = useRef({ dataFetch }).current;


  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const params = () => {
    if (!isBanner && !isRoute && !isNewsletter && !isSocial) {
      return `${process.env.REACT_APP_APP}/wp-json/mg/v1/${name}${
        lang ? `?lang=${lang}` : ""
      }${page ? `&page=${page}` : ""}${display ? `&display=${display}` : ""}${
        light ? `&light=${light}` : ""
      }${termOfSearch ? `&s=${termOfSearch}` : ""}${
        slug ? `&slug=${slug}` : ""
      }${_uid ? "&_uid=" + _uid : ""}${offerParams ? offerParams : ""}${
        categories ? "&category=" + categories : ""
      }`;
    } else if (isRoute) {
      return `${process.env.REACT_APP_APP}/wp-json/mg/v1/${name}
				${_uid ? "?_uid=" + _uid : ""}
				${termOfSearch ? `&s=${termOfSearch}` : ""}
				${slug ? `&slug=${slug}` : ""}
				${categories ? "&category=" + categories : ""}`;
    } else if (isSocial) {
      return `${process.env.REACT_APP_APP}/wp-json/df-social-wall/v1/${name}
				${lang ? `?lang=${lang}` : ""}
				${_uid ? "&_uid=" + _uid : ""}
				${slug ? `&slug=${slug}` : ""}`;
    } else {
      return `${process.env.REACT_APP_APP}${name}`;
    }
    
  };
  // console.log("name API", name);

  const fetchApi = async () => {
    
    try {
      
      const dataFetch = await Axios.get(params(), {
        cancelToken: source.token,
      });

      if (prevData.dataFetch !== dataFetch) {
        setDataFetch(dataFetch.data);

        setIsLoaded(true);
      } else {
        setIsLoaded(false);
      }
    } catch (err) {
      // console.log(err)
    }
  };

  useEffect(() => {
    setIsLoaded(false);

    fetchApi();

    return () => {
      source.cancel();
      prevData.dataFetch = dataFetch;
    };
  }, [
    lang,
    prevData,
    isRoute,
    isNewsletter,
    isSocial,
    isBanner,
    name,
    _uid,
    slug,
    termOfSearch,
    categories,
    display,
    delay,
    light,
    offerParams,
    page,
  ]);


  // si l'api renvoi un string on le converti en JSON avant de retourner
  return [
    typeof dataFetch == "string" ? JSON.parse(dataFetch) : dataFetch,
    isLoaded,
  ];
};
