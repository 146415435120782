import React, { useEffect, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * ## ScrollToTop
 * @component wrapper autour d'un switch de react-router
 * @example 
 * <ScrollToTop>
 *    <Switch>
 *      <Route/>
 *    </Switch>
 * </ScrollToTop>
 * @param {*} param0 
 * @see https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
 */

function ScrollToTop({ history, children }) {
  let { hash } = useLocation();

  let { title } = useSelector(state => state.page);

  useEffect(() => {
      let gotTop;

      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      // fonctionne pas avec history listen...
      if (element) {
        gotTop = setTimeout(() => {  
          window.scrollTo({
            behavior: 'smooth',
            top: title.offsetTop - 150,
            left: 0
          });
        }, 20);
      }
      let unlisten = history.listen(() => {
        if (!element) {
          gotTop = setTimeout(() => {  
            window.scrollTo({
              behavior: 'smooth',
              top: 0,
              left: 0
            });
          }, 20);
        }
    });
      
      
    return () => {
      clearTimeout(gotTop);
      unlisten();
    }
   
  }, [history, hash, title]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
