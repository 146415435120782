import React from "react";
import { Fade } from "react-awesome-reveal";

// Components
import { Text } from "./Text";

export const ImageText = (props) => {
  let { image, copyright } = props.datas;

  const renderHtml = () => {
    return (
      <Fade triggerOnce direction="top">
        <figure className="image-container">
          <img src={image.url} alt={image.alt} title={image.title} />
          {copyright ? <figcaption>{copyright}</figcaption> : ""}
        </figure>
        <Text {...props} />
      </Fade>
    );
  };

  return <>{renderHtml()}</>;
};
