import React from "react";
import FRLogo from "../../Assets/img/fr.svg";
import UKLogo from "../../Assets/img/uk.svg";
import { useSelector, useDispatch } from "react-redux";
import { generate } from "shortid";
import { UPDATE_LANG } from "../../Store/action";

// External lib
import ReactGA from "react-ga";

const List = React.memo(({ datas, dispatch, updateLang, lang }) => {
  let { ga } = useSelector((state) => state.global);

  const onClickEvent = () => {
    if (ga) {
      ReactGA.event({
        category: lang === "fr" ? "Langue" : "Lang",
        action: "Click",
        label: UPDATE_LANG,
      });
    }
  };

  return datas.map((item) => (
    <div
      key={generate()}
      data-lang={item.lang}
      data-lang-actif={item.lang === lang}
      className="lang-item"
      onClick={() => {
        dispatch(updateLang(item.lang));
        onClickEvent();
      }}
    >
      <img src={item.lang === "fr" ? FRLogo : UKLogo} alt={item.lang} />
    </div>
  ));
});

export const LanguageSwitch = (props) => {
  const { lang } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  let { datas, updateLang } = props;

  return (
    <div className="language-switch">
      <div className="lang">
        <List
          datas={datas}
          dispatch={dispatch}
          updateLang={updateLang}
          lang={lang}
        />
      </div>
    </div>
  );
};
