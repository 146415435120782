// GLOBAL STATE
export function updateGlobalStoreGlobal(datas) {
  return { type: GLOBAL_UPDATE_GLOBAL, value: datas };
}

export function updateGlobalStoreRoutes(datas) {
  return { type: GLOBAL_UPDATE_ROUTES, value: datas };
}

export function updateGlobalStoreLive(datas) {
  return { type: GLOBAL_UPDATE_LIVE, value: datas };
}

export function updateGlobalisLoaded(bool) {
  return { type: GLOBAL_IS_LOADED, value: bool };
}

export function updateGlobalStoreEvent(bool) {
  return { type: GLOBAL_BANNER_EVENT, value: bool };
}

export function updatePath(data) {
  return { type: PATH, value: data };
}

export function updatePathEn(data) {
  return { type: PATH_EN, value: data };
}

export function updateGlobalTitle(datas) {
  return { type: GLOBAL_TITLE, value: datas };
}

export function updateIsMobile(bool) {
  return { type: IS_MOBILE, value: bool };
}

export function updateIsTablet(bool) {
  return { type: IS_TABLET, value: bool };
}

export function updateIsDesktop(bool) {
  return { type: IS_DESKTOP, value: bool };
}

export function updateIsDesktopWide(bool) {
  return { type: IS_DESKTOP_WIDE, value: bool };
}

export function updateGlobalScrollLock(bool) {
  return { type: GLOBAL_IS_SCROLLLOCKED, value: bool };
}

export function updateBreadcrumbsFocus(bool) {
  return { type: BREADCRUMBS_FOCUS, value: bool };
}

export function updateLang(datas) {
  return { type: UPDATE_LANG, value: datas };
}

export function updateGA(bool) {
  return { type: GA, value: bool };
}

export function updateApp(bool) {
  return { type: APP, value: bool };
}

export const GLOBAL_UPDATE_GLOBAL = "UPDATE_GLOBAL";
export const GLOBAL_UPDATE_ROUTES = "UPDATE_ROUTES";
export const GLOBAL_UPDATE_LIVE = "UPDATE_LIVE";
export const GLOBAL_BANNER_EVENT = "UPDATE_EVENT";
export const GLOBAL_TITLE = "GLOBAL_TITLE";
export const GLOBAL_IS_LOADED = "IS_LOADED";
export const GLOBAL_IS_SCROLLLOCKED = "IS_SCROLLLOCKED";
export const PATH = "PATH";
export const PATH_EN = "PATH_EN";
export const IS_MOBILE = "IS_MOBILE";
export const IS_TABLET = "IS_TABLET";
export const IS_DESKTOP = "IS_DESKTOP";
export const IS_DESKTOP_WIDE = "IS_DESKTOP_WIDE";
export const BREADCRUMBS_FOCUS = "BREADCRUMBS_FOCUS";
export const UPDATE_LANG = "UPDATE_LANG";
export const GA = "GA";
export const APP = "APP";
