// import React, { useRef, useCallback, useLayoutEffect, useEffect } from "react";
// import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";
// import { Fade } from "react-awesome-reveal";
// import { Link } from "react-router-dom";

// // External lib
// import ReactGA from "react-ga";

// // external comps
// import { SlickSlider } from "./Slider";
// import { Loader } from "../Loader";

// // hooks
// import { useSelector } from "react-redux";
// import { useApi } from "../../Hooks/useApi";
// import Push from "../RealEstates/Push";

// export const Immobilier = ({ slug }) => {
//   // API call
//   let [dataImmo, dataImmoIsLoaded] = useApi({ name: "realestate" });
//   let { pushes = [], title, color, main, link } = dataImmo;

//   // Ref
//   let immoWrapperRef = useRef();
//   let immoScrollerRef = useRef();
//   let immoTopScrollRef = useRef();
//   let immoBottomScrollRef = useRef();

//   // Destructuring
//   let isLoaded = dataImmoIsLoaded;

//   // Shuffle pushes
//   const shuffle = (array) => {
//     return array.sort(() => Math.random() - 0.5);
//   };
//   let shuffledArray = shuffle(pushes);

//   // Slider settings
//   const settings = {
//     responsive: [
//       {
//         breakpoint: 10000,
//         settings: "unslick",
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           swipeToSlide: true,
//           dots: true,
//           infinite: true,
//           speed: 500,
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           prevArrow: false,
//           nextArrow: false,
//         },
//       },
//     ],
//   };

//   return (
//     <AutoScroll
//       className="immobilier"
//       settings={settings}
//       pushes={shuffledArray}
//       title={title}
//       color={color}
//       isLoaded={isLoaded}
//       slug={slug}
//       main={main}
//       wrapperRef={immoWrapperRef}
//       scrollerRef={immoScrollerRef}
//       topScrollRef={immoTopScrollRef}
//       bottomScrollRef={immoBottomScrollRef}
//       link={link}
//     />
//   );
// };

// export const AutoScroll = (props) => {
//   let {
//     isLoaded,
//     color,
//     title,
//     link,
//     pushes,
//     slug,
//     main,
//     settings,
//     className,
//     logo,
//     wrapperRef,
//     scrollerRef,
//     topScrollRef,
//     bottomScrollRef,
//   } = props;
//   let color2 = "rgba(211, 192, 115, 0)";

//   // Store
//   let breakpointStore = useSelector((state) => state.breakpoint);
//   let { ga } = useSelector((state) => state.global);

//   window.immoIsRunning = true;

//   // Destructuring
//   let { mobile, tablet } = breakpointStore;

//   // IE Haxx
//   let isIE = !!navigator.userAgent.match(/Trident\/7\./);

//   // Handle scroll
//   const handleScroll = useCallback(() => {
//     if (wrapperRef && wrapperRef.current) {
//       let bottom =
//         wrapperRef.current.scrollTop === wrapperRef.current.scrollHeight ||
//         wrapperRef.current.scrollTop >= wrapperRef.current.scrollHeight;
//       if (!bottom && window.immoIsRunning) {
//         wrapperRef.current.scrollBy({ behavior: "smooth", top: 200, left: 0 });
//       }
//     }
//   }, [wrapperRef]);

//   // Arrows and gradients
//   const handleScrolledElements = useCallback(() => {
//     if (
//       wrapperRef &&
//       wrapperRef.current &&
//       bottomScrollRef &&
//       bottomScrollRef.current &&
//       topScrollRef &&
//       topScrollRef.current &&
//       scrollerRef &&
//       scrollerRef.current
//     ) {
//       let el = wrapperRef.current;
//       let bottom =
//         wrapperRef.current.scrollTop ===
//           wrapperRef.current.scrollHeight - 400 ||
//         wrapperRef.current.scrollTop >= wrapperRef.current.scrollHeight - 400;
//       let nodes = [...el.childNodes];

//       if (nodes.length > 0) {
//         wrapperRef.current.scrollTop > 50
//           ? (topScrollRef.current.style.opacity = 1)
//           : (topScrollRef.current.style.opacity = 0);
//         if (bottom) {
//           bottomScrollRef.current.style.opacity = 0;
//           scrollerRef.current.style.opacity = 0;
//           window.immoIsRunning = false;
//         } else {
//           bottomScrollRef.current.style.opacity = 1;
//           scrollerRef.current.style.opacity = 1;
//         }
//       }
//     } else {
//       bottomScrollRef.current.style.opacity = 1;
//       scrollerRef.current.style.opacity = 1;
//     }
//   }, [wrapperRef, scrollerRef, topScrollRef, bottomScrollRef]);

//   // Mouse handlers
//   const handleMouseEnter = () => {
//     if (!mobile) {
//       window.immoIsRunning = false;
//     }
//   };

//   const handleMouseLeave = () => {
//     if (wrapperRef && wrapperRef.current) {
//       let bottom =
//         wrapperRef.current.scrollTop === wrapperRef.current.scrollHeight ||
//         wrapperRef.current.scrollTop >= wrapperRef.current.scrollHeight;

//       if (!bottom) {
//         if (!mobile) {
//           window.immoIsRunning = true;
//         }
//       }
//     }
//   };

//   const onClickEvent = () => {
//     if (ga) {
//       ReactGA.event({
//         category: link.all_realestate.title,
//         action: "Click",
//         label: link.all_realestate.title,
//       });
//     }
//   };

//   useEffect(() => {
//     if (!isIE) {
//       if (!mobile) {
//         window.immoIsRunning = setInterval(handleScroll, 3000);

//         return () => {
//           clearInterval(window.immoIsRunning);
//         };
//       }
//     }
//   }, [mobile, isIE, handleScroll]);

//   useLayoutEffect(() => {
//     if (!mobile) {
//       let el = wrapperRef.current;
//       if (wrapperRef && el) {
//         el.addEventListener("scroll", handleScrolledElements);

//         return () => {
//           el.removeEventListener("scroll", handleScrolledElements);
//         };
//       }
//     }
//   }, [wrapperRef, topScrollRef, isLoaded, mobile, handleScrolledElements]);

//   return (
//     <Fade triggerOnce cascade direction="top">
//       <aside
//         className={className}
//         style={{ backgroundColor: color }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}>
//         {isLoaded ? (
//           <>
//             <h2 className="block-title">
//               {logo && <img src={logo} alt="" />}
//               {title}
//             </h2>
//             {pushes.length > 0 ? (
//               <>
//                 <div className="pushes-wrapper">
//                   <div className="pushes" ref={wrapperRef}>
//                     {mobile || tablet
//                       ? settings && (
//                           <SlickSlider settings={settings}>
//                             {pushes.map((block) =>
//                               CreateComponentsByJSON(block, false, false)
//                             )}
//                           </SlickSlider>
//                         )
//                       : pushes.map((block) =>
//                           CreateComponentsByJSON(block, false, false)
//                         )}
//                   </div>

//                   <span
//                     className="top-gradient"
//                     ref={topScrollRef}
//                     style={{
//                       background: `linear-gradient(180deg, ${color} 0%, ${color2} 100%)`,
//                       opacity: 0,
//                     }}></span>
//                   <span
//                     className="gradient"
//                     ref={bottomScrollRef}
//                     style={{
//                       background: `linear-gradient(0deg, ${color} 0%, ${color2} 100%)`,
//                     }}></span>
//                   <button
//                     className="scroller"
//                     ref={scrollerRef}
//                     onClick={() =>
//                       wrapperRef.current.scrollBy({
//                         behavior: "smooth",
//                         top: 200,
//                         left: 0,
//                       })
//                     }>
//                     <i className="icon icon-down-arrow"></i>
//                   </button>
//                 </div>

//                 {link && (
//                   <div className="immobilier-linkAll" onClick={onClickEvent}>
//                     <Link to={link.all_realestate.url} className="btn">
//                       {link.all_realestate.title}
//                     </Link>
//                   </div>
//                 )}

//                 {slug !== "immobilier" ? CreateComponentsByJSON(main) : null}
//               </>
//             ) : null}
//           </>
//         ) : (
//           <Loader />
//         )}
//       </aside>
//     </Fade>
//   );
// };


import React, { useRef, useCallback, useEffect, useState } from "react";
import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import Slider from "react-slick"; // Import Slider from react-slick
import { Loader } from "../Loader";
import { useSelector } from "react-redux";
import { useApi } from "../../Hooks/useApi";
import ReactGA from "react-ga";

export const Immobilier = ({ slug }) => {
  let [dataImmo, dataImmoIsLoaded] = useApi({ name: "realestate" });
  let { pushes = [], title, color, main, link } = dataImmo;

  let immoWrapperRef = useRef();

  let isLoaded = dataImmoIsLoaded;

  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };
  let shuffledArray = shuffle(pushes);

  const settings = {
    swipeToSlide: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <AutoScroll
      className="immobilier"
      settings={settings}
      pushes={shuffledArray}
      title={title}
      color={color}
      isLoaded={isLoaded}
      slug={slug}
      main={main}
      wrapperRef={immoWrapperRef}
      link={link}
    />
  );
};

export const AutoScroll = (props) => {
  let {
    isLoaded,
    color,
    title,
    link,
    pushes,
    slug,
    main,
    settings,
    className,
    wrapperRef,
  } = props;

  const sliderRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      changeSlide();
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, []);

  const changeSlide = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, []);

  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  return (
    <Fade triggerOnce cascade direction="left">
      <aside
        className={className}
        style={{ backgroundColor: color, overflowY: "hidden" }} 
      >
        {isLoaded ? (
          <>
            <br />
            <br />
            <Link to={link.all_realestate.url}>
              <p style={{ textAlign: "center", fontSize: "38px", fontStyle: "italic" }}>
                <span style={{ display: "block" }}>Retrouvez</span>
                <span style={{ display: "block" }}>l'ensemble</span>
                <span style={{ display: "block" }}>de nos</span>
                {/* <span style={{ display: "block" }}>{pushes.length}</span> */}
                <span style={{ display: "block" }}>annonces</span>
                <span style={{ display: "block" }}>immobilières</span>
              </p>
            </Link>
            <br />
            <div className="pushes-wrapper" style={{ overflowY: "hidden" }}>
              <div className="pushes" ref={wrapperRef} style={{ overflowY: "hidden" }}>
                {/* Use Slider from react-slick */}
                <Slider ref={sliderRef} {...settings}>
                  {shuffle(pushes).slice(0, 5).map((block, index) => (
                    <div key={block._uid}>
                      <Link to={link.all_realestate.url}>
                        <img src={block.datas.preview_image.url.single} alt={block.datas.preview_image.alt} />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </aside>
    </Fade>
  );
};