import React from "react";
import { Link } from "react-router-dom";
import { Wysiwyg } from "../Wysiwyg";
import { Fade } from "react-awesome-reveal";
import { generate } from "shortid";

export const Introduction = (props) => {
  let {
    title = [],
    chapo,
    categories = [],
    color,
    date,
    preview_image,
    copyright,
  } = props;

  let renderArray = (data, classname) => {
    if (data.link) {
      return (
        <Link
          to={data.link.url}
          title={data.text}
          className={classname}
          key={generate()}>
          <span style={{ color }}>{data.text}</span>
        </Link>
      );
    }
  };

  return (
    <>
      <div className="introduction">
        {categories.length > 0 &&
          categories.map((data, index) =>
            index === 0 ? renderArray(data, "category") : null
          )}
        <Fade triggerOnce cascade direction="top">
          {title.text && (
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: `${title.text}` }}
            />
          )}
          <Wysiwyg className="chapo">{chapo}</Wysiwyg>

          <span className="time">{date}</span>
        </Fade>
      </div>

      {preview_image && (
        <Fade triggerOnce direction="top" className="main-picture">
          <figure className="image-container">
            <img
              src={preview_image.url.single}
              alt={preview_image.alt}
              title={preview_image.title}
            />
            {copyright && <figcaption>{copyright}</figcaption>}
          </figure>
        </Fade>
      )}
    </>
  );
};
