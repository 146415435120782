import React from "react";
import { SocialNetworks } from "../SocialNetworks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";
import { generate } from "shortid";

export const Footer = () => {
  let dataGlobal = useSelector((state) => state.global).global;
  let { footer, social_networks } = dataGlobal;
  let cookiesManagement = useTranslate("cookiesManagement");
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-menu">
            {footer.links.map((item) => (
              <div className="footer-item" key={generate()}>
                <Link to={item.url} title={item.text} target="_self">
                  {item.text}
                </Link>
              </div>
            ))}
            <div className="footer-item">
              <button
                onClick={() => window.tarteaucitron.userInterface.openPanel()}>
                {cookiesManagement}
              </button>
            </div>
          </div>
          <div className="footer-social-networks">
            <SocialNetworks {...social_networks} />
          </div>
        </div>
      </footer>
    </>
  );
};
