// import React, { useEffect } from "react";
// import { useApi } from "../../Hooks/useApi";

// import Push from "./Push";

// export const NextPage = ({
//   parentCallback,
//   next,
//   last,
//   display,
//   offerParams,
// }) => {
//   const [dataFetch, isLoaded] = useApi({
//     name: `realestate/list/`,
//     page: next,
//     display: display,
//     offerParams,
//   });

//   useEffect(() => {
//     if (next === last) {
//       return parentCallback(true);
//     }
//   }, [parentCallback, next, last]);

//   /* Plus de biens buttom correction      */
//   return isLoaded
//     ? dataFetch.pushes
//         .slice(6)
//         .map(({ datas, _uid }) => <Push {...datas} key={_uid} />)
//     : null;
// };



import React, { useEffect } from "react";
import { useApi } from "../../Hooks/useApi";
import Push from "./Push";

export const NextPage = ({
  parentCallback,
  next,
  last,
  display,
  offerParams,
  setTotalContent,
}) => {
  const [dataFetch, isLoaded] = useApi({
    name: `realestate/list/`,
    page: next,
    display: display,
    offerParams,
  });

  useEffect(() => {
    if (isLoaded && dataFetch.pushes) {
      // Update the total content with the new data fetched
      setTotalContent(prevContent => [...prevContent, ...dataFetch.pushes]);
    }
  }, [isLoaded, dataFetch.pushes, setTotalContent]);

  useEffect(() => {
    if (next === last) {
      parentCallback(true);
    }
  }, [parentCallback, next, last]);

  return null;
};
