import React, { useRef, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";

// external comps
import { Button } from "../Buttons";

// Hooks
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";

export const Form = (props) => {
  let globalStore = useSelector((state) => state.global);
  let { lang } = useSelector((state) => state.lang);
  let { google_recaptcha_public_key } = globalStore.global;
  let { title, text, optin_newsletter } = props.bloc_newsletter;

  let [success, setSuccess] = useState(false);
  let [message, setMessage] = useState(null);
  let [email, setEmail] = useState("");
  let [optinIsChecked, setOptinIsChecked] = useState(false);
  let isButtonEnabled = email.length > 0 && optinIsChecked;

  // const {register, handleSubmit, setValue, setError, errors = []} = useForm({mode: 'onChange'});
  const { register, handleSubmit, setValue, setError, errors = [] } = useForm();

  let captchaRef = useRef(null);
  let button = useTranslate("newsletterButton");
  let placeholder = useTranslate("newsletterPlaceholder");
  let validEmail = useTranslate("newsletterErrorInvalid");
  let requiredEmail = useTranslate("newsletterErrorRequired");

  useEffect(() => {
    register({ name: "g-recaptcha-response", required: "Required" });
  }, [register]);

  const send = (values) => {
    let fetch = Axios.get(
      `${process.env.REACT_APP_APP}/wp-json/df-newsletter/v1/save/${
        lang ? `?lang=${lang}` : ""
      }&value=${values.newsletter_email}`
    );

    fetch.then((res) => {
      let { status, data } = res;

      if (data.success && status === 200 && !Object.keys(errors).length) {
        setSuccess(true);
        setMessage(data.data.msg);
      } else if (!data.success) {
        setSuccess(false);

        let error = data.data.errors;

        if (error) {
          if (error["g-recaptcha-response"]) {
            setError(
              "g-recaptcha-response",
              "g-recaptcha-response",
              error["g-recaptcha-response"]
            );
          }
          if (error.newsletter_email) {
            setError(
              "newsletter_email",
              "newsletter_email",
              error.newsletter_email
            );
            setTimeout(() => {
              captchaRef.current.reset();
            }, 50);
          }
        }
      }
    });
  };

  function createMarkup() {
    return { __html: optin_newsletter };
  }

  return (
    <>
      {success ? (
        <div className="success">
          <i className="icon icon-newspaper"></i>
          <span className="title"> {message} <img src="https://uploadsv4.gips-cloud.com/logo-gazette-123497.gif" style={{width:"40px", marginBottom: "20px"}}></img></span>
        </div>
      ) : (
        <>
          <span className="title">{title}</span>
          <p className="text">{text}</p>
          <form onSubmit={handleSubmit(send)}>
            <div className="field">
              <label className="label">{placeholder}</label>
              <input
                name="newsletter_email"
                placeholder={placeholder}
                ref={register({
                  required: requiredEmail[0],
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: validEmail,
                  },
                })}
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <span className="error">
                {errors.newsletter_email && errors.newsletter_email.message}
              </span>
            </div>

            <div className="field">
              <div className="select-item">
                <input
                  type="checkbox"
                  value={optinIsChecked ? 1 : 0}
                  name="newsletter_optin_rgpd"
                  checked={optinIsChecked}
                  onChange={() => setOptinIsChecked(!optinIsChecked)}
                  ref={register({ required: "Optin required" })}
                />
                <label
                  htmlFor="newsletter_optin"
                  dangerouslySetInnerHTML={createMarkup()}
                ></label>
              </div>
            </div>

            <div className="flex">
              {google_recaptcha_public_key ? (
                <div className="field">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={google_recaptcha_public_key}
                    className="g-recaptcha"
                    hl={lang}
                    data-size="compact"
                    onChange={(val) => {
                      setValue("g-recaptcha-response", val);
                    }}
                  />
                  <span className="error">
                    {errors["g-recaptcha-response"] &&
                      errors["g-recaptcha-response"].message}
                  </span>
                </div>
              ) : null}
              <Button type="submit" className="red" disabled={!isButtonEnabled}>
                {button}
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};
