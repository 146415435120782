import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";

// Components
import { Banners } from "../Banners";
import { PageTitle } from "../PageTitle";
import { Immobilier } from "./Immobilier";
import { MostPopular } from "./MostPopular";
import { Live } from "./Live";
import { Breadcrumbs } from "../Breadcrumbs";
import { ButtonSeeMore } from "../Buttons";
import { NextPage } from "./NextPage";
import { Alert } from "../Alert";
import ImmoButton from "../ImmoButton";

// hooks
import { useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";

export const PushLayout = ({ dataNews, dataHome, dataBanners, _uid, slug }) => {
  const { pushes = [], breadcrumbs, next, last } = dataNews;
  let breakpointStore = useSelector((state) => state.breakpoint);
  // Destructuring
  let { mobile } = breakpointStore;

  window.persistentNext =
    typeof window.persistentNext !== "undefined" ? window.persistentNext : next;
  window.persistentData =
    typeof window.persistentData !== "undefined" ? window.persistentData : [];
  let tradSeeMore = useTranslate("seemore");
  let [isLast, setIsLast] = useState(false);
  let [newContent, setNewContent] = useState(null);
  let [nbitem, setNbItem] = useState(0);
  let newContentRef = useRef("");

  useEffect(() => {
    if (
      window.persistentNext < 1 ||
      window.persistentNext === null ||
      window.persistentNext === ""
    ) {
      setIsLast(true);
    }
    return function cleanup() {
      window.persistentNext = undefined;
      window.persistentData = undefined;
    };
  }, [next, setIsLast]);

  const parentCallback = (boolean) => {
    setIsLast(boolean);
  };

  const CallNextPage = () => {
    let nextpage = (
      <NextPage
        parentCallback={parentCallback}
        next={window.persistentNext}
        last={last}
        display="8"
        slug={slug}
      />
    );
    window.persistentData.push(nextpage);
    setNewContent(window.persistentData);
    setNbItem(window.persistentData.length);
    window.persistentNext++;

    // crappy solution
    setTimeout(() => {
      if (newContentRef && newContentRef.current) {
        window.scrollTo(0, newContentRef.current.offsetTop - 156);
      }
    }, 2000);
  };

  return (
    <>
      {breadcrumbs ? <Breadcrumbs {...dataNews} /> : ""}

      <div className="main-wrapper">
        {!dataHome && <PageTitle {...dataNews} />}

        <Fade
          direction="top"
          duration="500"
          cascade
          triggerOnce
          className="hidden-s hidden-m">
          <Immobilier _uid={_uid} slug={slug} />
          {/* Insert the new ImmoButton component */}
          {/* <ImmoButton _uid={_uid} slug={slug} /> */}
          <br></br>
          <MostPopular _uid={_uid} />
          <div className="sticky">
            <Banners {...dataBanners} name="left_halfpage" />
          </div>
        </Fade>

        <div className="main-content">
          {dataHome ? <Alert {...dataHome} /> : null}

          <Fade duration="1000" cascade triggerOnce>
            <main>
              {pushes
                .slice(0, 1)
                .map((block) => CreateComponentsByJSON(block, true, true))}
            </main>
          </Fade>

          <Fade
            direction="top"
            duration="500"
            cascade
            triggerOnce
            className="hidden-l hidden-xl">
            <div className="row">
              <Immobilier _uid={_uid} slug={slug} />
              <div className="hidden-s">
                <Live _uid={_uid} />
                <Banners {...dataBanners} name="right_halfpage" />
              </div>
            </div>
          </Fade>

          <Fade direction="top" duration="500" cascade triggerOnce>
            <main className="row">
              {pushes
                .slice(1, 3)
                .map((block) => CreateComponentsByJSON(block, true, true))}
            </main>
          </Fade>

          <div className="hidden-m hidden-l hidden-xl">
            <Banners {...dataBanners} name="right_halfpage" />
          </div>

          <Banners {...dataBanners} name="banner_1" />

          <Fade direction="top" duration="500" cascade triggerOnce>
            <main className="row">
              {pushes
                .slice(3, 5)
                .map((block) => CreateComponentsByJSON(block, true, true))}
            </main>
          </Fade>

          <Banners {...dataBanners} name="banner_2" />

          {dataHome ? (
            <>
              <Fade direction="top" duration="500" cascade triggerOnce>
                <main>
                  {pushes
                    .slice(5, 6)
                    .map((block) => CreateComponentsByJSON(block, true, true))}
                </main>
              </Fade>

              <Banners {...dataBanners} name="banner_3" />

              <Fade direction="top" duration="500" cascade triggerOnce>
                <main className="row">
                  {pushes
                    .slice(6, 8)
                    .map((block) => CreateComponentsByJSON(block, true, true))}
                </main>
              </Fade>

              <Banners {...dataBanners} name="banner_4" />
            </>
          ) : (
            <>
              <Fade direction="top" duration="500" cascade triggerOnce>
                <main className="row">
                  {pushes
                    .slice(5, 9)
                    .map((block) => CreateComponentsByJSON(block, true, true))}
                </main>
              </Fade>

              <Banners {...dataBanners} name="banner_3" />

              <Fade direction="top" duration="500" cascade triggerOnce>
                <main className="row">
                  {pushes
                    .slice(9, 11)
                    .map((block) => CreateComponentsByJSON(block, true, true))}
                </main>
              </Fade>

              <Banners {...dataBanners} name="banner_4" />
            </>
          )}

          {newContent &&
            newContent.map((item, id) => (
              <div
                ref={newContentRef}
                className={`newcontent-${id + 1}`}
                data-nbitem={nbitem}
                key={`newcontent-${id}`}>
                {item}
              </div>
            ))}

          {!isLast ? (
            <div className="seemore">
              <ButtonSeeMore action={() => CallNextPage()}>
                {tradSeeMore}
                <span className="more">+</span>
              </ButtonSeeMore>
            </div>
          ) : null}

          <Fade
            direction="top"
            duration="500"
            cascade
            triggerOnce
            className="hidden-l hidden-xl">
            <div className="row change-order">
              <Banners {...dataBanners} name="left_halfpage" />
              {!mobile && !slug ? <MostPopular _uid={_uid} /> : null}
            </div>
          </Fade>
        </div>
      </div>

      <Fade
        direction="top"
        duration="500"
        cascade
        triggerOnce
        className="right-col hidden-s hidden-m">
        <Live _uid={_uid} />
        <div className="sticky">
          <Banners {...dataBanners} name="right_halfpage" />
        </div>
      </Fade>
    </>
  );
};
