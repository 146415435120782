import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

function Test() {
  const [responseData, setResponseData] = useState("");

  useEffect(() => {
    async function fetchData() {
      var rawResponse = await fetch(
        "https://apiv4.gips-cloud.com/api/client/test"
      );
      var response = await rawResponse.json();
      setResponseData(response);
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>{responseData}</h1>
    </div>
  );
}

export default Test;
