import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "scroll-behavior-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { headerReducer } from './Store/Reducers/headerReducer';
import { langReducer } from './Store/Reducers/langReducer';
import { globalReducer } from './Store/Reducers/globalReducer';
import { pageReducer } from './Store/Reducers/pageReducer';
import { articleReducer } from './Store/Reducers/articleReducer';
import { breakpointReducer } from './Store/Reducers/breakpointReducer';

const reducer = combineReducers({
	header: headerReducer, 
	lang: langReducer,
	global: globalReducer,
	page: pageReducer,
	article: articleReducer,
	breakpoint: breakpointReducer
})

const store = createStore(reducer);

ReactDOM.render(<Provider store={store}>
		<App />
	</Provider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
