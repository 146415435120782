import React from "react";
import { Fade } from "react-awesome-reveal";
import { generate } from "shortid";

export const ImageCols = ({ datas }) => {
  const renderHtml = (item) => {
    return (
      <Fade triggerOnce direction="top" key={generate()}>
        <figure className="image-container">
          <img
            src={item.image.url}
            alt={item.image.alt}
            title={item.image.title}
          />
          {item.copyright ? <figcaption>{item.copyright}</figcaption> : ""}
        </figure>
      </Fade>
    );
  };
  return (
    <>
      {
        <div className="flex">
          {datas.map((item) => {
            return renderHtml(item);
          })}
        </div>
      }
    </>
  );
};
