import React, {useEffect, useRef, useState} from "react"
import {useSelector, useDispatch} from 'react-redux';

// Components
import {Metatags} from '../Components/Metatags';
import {PushLayout} from '../Components/Pushes/PushLayout';
import {SocialWall} from '../Components/SocialWall';
import {Banners} from '../Components/Banners'
import {SkeletonPage} from '../Components/Skeletons/SkeletonPage';

// External lib
import ReactGA from 'react-ga';

// Hooks
import {useApi} from '../Hooks/useApi';

export const TemplateHome = () => {
    let [dataNews, dataNewsIsLoaded] = useApi({name: 'news/home', display: 8});
    let [dataHome, dataHomeIsLoaded] = useApi({name: 'home'});
    let {global, ga} = useSelector(state => state.global);
    let {lang} = useSelector(state => state.lang);
    let {banners, header} = global;

    let {metas, microdonnees, seo_link} = dataHome;

    let [dataBanners, dataBannersIsLoaded] = useApi({name: `${banners.homepage}`, isBanner: true});
    let isLoaded = dataNewsIsLoaded && dataHomeIsLoaded && dataBannersIsLoaded;
    let pageRef = useRef();
    let dispatch = useDispatch();

    // GA
    const [microdatas, setMicrodatas] = useState({});
    useEffect(() => {
        if (isLoaded) {
            // if (isLoaded && ga) {
            // ReactGA.set({
            //         "@context": "https://schema.org",
            //         "@type": "WebSite",
            //         "url": microdonnees.website,
            //         "potentialAction": {
            //             "@type": "SearchAction",
            //             "target": `${window.location.origin} ${lang === 'fr' ? '/resultats-de-recherche' : '/en/search-results'}?s={search_term_string}`,
            //             "query-input": "required name=search_term_string"
            //         }
            //     },
            //     {
            //         "@context": "https://schema.org",
            //         "@type": "Organization",
            //         "url": microdonnees.website,
            //         "logo": microdonnees.logo_url
            //     })
            setMicrodatas({
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": microdonnees.website,
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": `${window.location.origin} ${lang === 'fr' ? '/resultats-de-recherche' : '/en/search-results'}?s={search_term_string}`,
                        "query-input": "required name=search_term_string"
                    }
                },
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": microdonnees.website,
                    "logo": microdonnees.logo_url
                });
        }
        // }, [isLoaded, ga]);
    }, [isLoaded]);

    // Dispatch
    useEffect(() => {
        dispatch({type: "pageslug", value: 'homepage'})
    }, [dispatch, banners, isLoaded, pageRef])

    return (
        <>
            {isLoaded ?
                <>
                    <Metatags {...seo_link} {...metas} microdatas={microdatas}/> 
                    <div className="page">
                        <Banners {...dataBanners} name="billboard"/>
                        <div className="content">
                            <div className="container">
                                <div className="home">
                                    <PushLayout
                                        dataNews={dataNews}
                                        dataHome={dataHome}
                                        dataBanners={dataBanners}
                                    />
                                    <SocialWall/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : <SkeletonPage/>
            }
        </>
    )
}