import React from "react";
import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";
import { generate } from "shortid";

export const ArticleSlider = ({ datas }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderHtml = (item) => {
    return (
      <Fade triggerOnce cascade direction="top" key={generate()}>
        <figure className="image-container">
          <img
            src={item.image.url}
            alt={item.image.alt}
            title={item.image.title}
          />
          {item.copyright ? <figcaption>{item.copyright}</figcaption> : ""}
        </figure>
      </Fade>
    );
  };

  return <Slider {...settings}>{datas.map((item) => renderHtml(item))}</Slider>;
};
