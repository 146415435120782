import { IS_MOBILE, IS_TABLET, IS_DESKTOP, IS_DESKTOP_WIDE } from "../action";

/**
 * REDUCER DE LA CONFIGURATION DES BREAKPOINTS
 * @param {*} state
 * @param {*} action
 */

const initialState = {
  mobile: window.matchMedia("(max-width:767px)").matches ? true : false,
  tablet: window.matchMedia("(min-width:768px) and (max-width:1024px)").matches
    ? true
    : false,
  desktop: window.matchMedia("(min-width:1025px) and (max-width:1365px)")
    .matches
    ? true
    : false,
  desktopWide: window.matchMedia("(min-width:1366px)").matches ? true : false,
};

export function breakpointReducer(state = initialState, action) {
  switch (action.type) {
    case IS_MOBILE:
      return { ...state, mobile: action.value };
    case IS_TABLET:
      return { ...state, tablet: action.value };
    case IS_DESKTOP:
      return { ...state, desktop: action.value };
    case IS_DESKTOP_WIDE:
      return { ...state, desktopWide: action.value };
    default:
      return state;
  }
}
