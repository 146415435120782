import React from "react";
import { Link } from "react-router-dom";

// ImmoButton component
const ImmoButton = ({ _uid, slug }) => {

  const handleButtonClick = () => {
    // Add the desired behavior when the button is clicked
    console.log("ImmoButton clicked!");
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const buttonStyle = {
    padding: "15px 30px", // Adjust padding for a larger button
    background: "linear-gradient(to right, #d4c073, #d4c073)", // Gold gradient background
    color: "#ffffff", // Button text color
    textDecoration: "none",
    borderRadius: "8px",
    fontSize: "30px",
    transition: "background 0.3s ease"
  };

  const buttonHoverStyle = {
    background: "linear-gradient(to right, #d4c073, #c8b365)", // gradient background on hover
  };

  return (
    <div style={buttonContainerStyle} className="immo-button-container">
      <Link
        to={`/annonces-immobilieres`}
        onClick={handleButtonClick}
        style={{ ...buttonStyle, ...buttonHoverStyle }}
      >
        Immobilier
      </Link>
    </div>
  );
};

export default ImmoButton;