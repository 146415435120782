import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

export const Menu = ({
  items = [],
  mobile,
  desktop,
  desktopWide,
  menuOpen,
  closeMenu,
  toggleBurger,
  menuContainerRef,
}) => {
  let menuRef = useRef();
  let [width, setWidth] = useState();
  let [height, setHeight] = useState();
  let [buttonMore, setButtonMore] = useState();

  const changeLinkColor = (event) => {
    event.target.style.color = event.target.dataset.colorDefault;
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const ButtonMore = () => {
      return (
        <div className="open-menu-container">
          <button
            className="open-menu"
            onClick={() => {
              closeMenu();
            }}
          >
            <span className={`operator ${menuOpen ? "less" : "more"}`}>
              <span>{menuOpen ? "-" : "+"}</span>
            </span>
            <span className="hidden-s hidden-m">Menu</span>
          </button>
        </div>
      );
    };

    const showButtonMore = (e) => {
      if (menuRef.current) {
        let nodes = [...menuRef.current.childNodes];
        let widthMenu = 0;
        let heightMenu = 0;

        // à retaper en switch case
        if (mobile) {
          nodes.slice(0, 7).map((child) => {
            heightMenu += child.clientHeight;
            return heightMenu;
          });
          setHeight(heightMenu);
          setButtonMore(ButtonMore);
        } else if (desktop) {
          nodes.slice(0, 4).map((child) => {
            widthMenu += child.clientWidth;
            return widthMenu;
          });

          setWidth(widthMenu + 2);
          setButtonMore(ButtonMore);
        } else if (desktopWide) {
          nodes.slice(0, 7).map((child) => {
            widthMenu += child.clientWidth;
            return widthMenu;
          });

          setWidth(widthMenu + 50);

          if (menuContainerRef.current.scrollHeight > 45) {
            setButtonMore(ButtonMore);
          }
        }
      }
    };

    showButtonMore();

    window.addEventListener("resize", showButtonMore);

    return () => {
      window.removeEventListener("resize", showButtonMore);
    };
  }, [
    menuRef,
    mobile,
    desktop,
    desktopWide,
    menuOpen,
    closeMenu,
    menuContainerRef,
  ]);

  const renderList = (item, index) => {
    return (
      <li className="menu-item" key={index}>
        <NavLink
          activeClassName="selected"
          activeStyle={{ color: item.color }}
          to={item.url}
          onClick={() => toggleBurger && toggleBurger(false)}
          onMouseOver={(event) => changeLinkColor(event, item.color)}
          onMouseOut={(event) =>
            (event.target.style.color = event.target.classList.contains(
              "selected"
            )
              ? event.target.dataset.colorDefault
              : "black")
          }
          data-color-default={item.color}
        >
          {item.text}
          <span
            className="border"
            style={{ backgroundColor: item.color }}
          ></span>
        </NavLink>
      </li>
    );
  };

  return (
    <>
      <nav
        className="menu"
        ref={menuContainerRef}
        style={{ width: width, maxHeight: height }}
      >
        <ul ref={menuRef} onClick={handleRefresh}>
          {items.map((item, index, arr) => {
            return renderList(item, index, arr);
          })}
        </ul>
      </nav>
      {buttonMore}
    </>
  );
};
