import React, {useState} from 'react';
import {Link} from 'react-router-dom';

export const ButtonSeeMore = ({children, action, className}) => {

    return (
        <button className={`${className || ''} button`} onClick={action}>
            {children}
        </button>
    );
};

export const Button = (props) => {
    let [animation, setAnimation] = useState(false);
    let {children, action, className, type, link, disabled} = props;
    let {component, datas} = props;

    const renderElement = () => {
        if (link) {
            return (
                <Link
                    to={link}
                    className={`button ${className || ''} ${animation ? 'animation' : ''}`}
                    onMouseEnter={() => setAnimation(true)}
                    onAnimationEnd={() => setAnimation(false)}>
                    {children}
                </Link>
            )
        } else if (component === 'cta') {
            return (
                <Link
                    to={datas.link.url}
                    target={datas.link.target}
                    className={`button red ${animation ? 'animation' : ''}`}
                    onMouseEnter={() => setAnimation(true)}
                    onAnimationEnd={() => setAnimation(false)}>
                    {datas.link.title}
                </Link>
            )
        } else {
            return (
                <button
                    disabled={disabled}
                    className={`button ${className || ''} ${animation ? 'animation' : ''}`}
                    type={type}
                    onClick={action}
                    onMouseEnter={() => setAnimation(true)}
                    onAnimationEnd={() => setAnimation(false)}>
                    {children}
                </button>
            )
        }
    }

    return (
        renderElement()
    );
};


