import { useState, useLayoutEffect } from 'react';
import * as translation from '../../src/Utils/translation.json';
import { useSelector } from 'react-redux';

// Hook personalisé pour récupérer les traductions

export const useTranslate = (keyword) => {
	let [ word, setWord] = useState('…');
	let { lang } = useSelector(state => state.lang);

	useLayoutEffect(()=>{
		if(translation){
			// console.log(keyword,translation.default[keyword][lang])
			setWord(translation.default[keyword][lang])
		}
	},[lang, keyword]);

	return [word]
}; 
