import { saveState, loadState } from "../../Utils/loadState";
import { PATH, PATH_EN } from "../action";

/**
 * REDUCER RECUP PATH SINGLE
 * @param {*} state
 * @param {*} action
 */

const initialState = {
  path: loadState("path") || "",
  path_en: loadState("path_en") || "",
};

export function articleReducer(state = initialState, action) {
  switch (action.type) {
    case PATH:
      saveState("path", action.value);
      return { ...state, path_fr: action.value };
    case PATH_EN:
      saveState("path_en", action.value);
      return { ...state, path_en: action.value };
    default:
      return state;
  }
}
