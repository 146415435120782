import React from "react";
import { Glass } from "../Glass";

export const Burger = ({
  closeBurger,
  toggleLiveModal,
  toggleNLModal,
  glass,
  toggleGlass,
}) => {
  return (
    <>
      <div
        className="toggle-menu"
        onClick={() => {
          closeBurger();

          toggleLiveModal && toggleLiveModal(false);
          toggleNLModal && toggleNLModal(false);

          if (toggleGlass) {
            glass ? toggleGlass(false) : toggleGlass(true);
          }
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      {glass ? <Glass glass={glass} /> : null}
    </>
  );
};
