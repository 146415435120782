import {
  GLOBAL_UPDATE_GLOBAL,
  GLOBAL_UPDATE_ROUTES,
  GLOBAL_UPDATE_LIVE,
  GLOBAL_IS_LOADED,
  GLOBAL_BANNER_EVENT,
  GLOBAL_IS_SCROLLLOCKED,
  APP,
  BREADCRUMBS_FOCUS,
  GA,
} from "../action";

let initialState = {
  isLoaded: false,
  global: {},
  live: {},
  routes: {},
  event: {},
  lockScroll: false,
  breadcrumbsFocus: false,
  ga: false,
  app: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function globalReducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_UPDATE_GLOBAL:
      return { ...state, global: action.value, banner: action.value.banners };
    case GLOBAL_UPDATE_ROUTES:
      return { ...state, routes: action.value };
    case GLOBAL_UPDATE_LIVE:
      return { ...state, live: action.value };
    case GLOBAL_BANNER_EVENT:
      return { ...state, event: action.value };
    case GLOBAL_IS_LOADED:
      return { ...state, isLoaded: action.value };
    case GLOBAL_IS_SCROLLLOCKED:
      return { ...state, lockScroll: action.value };
    case APP:
      return { ...state, app: action.value };
    case GA:
      return { ...state, ga: action.value };
    case BREADCRUMBS_FOCUS:
      return { ...state, breadcrumbsFocus: action.value };
    default:
      return state;
  }
}
