import React from "react";
import { Helmet } from "react-helmet";

import ReactGA from "react-ga";
import { useSelector } from "react-redux";

export const Metatags = (props) => {
  const {
    title,
    description,
    og_fb_title,
    og_fb_desc,
    og_fb_img,
    og_fb_img_width,
    og_fb_img_height,
    og_twitter_title,
    og_twitter_desc,
    og_twitter_img,
    canonical,
    alternate,
    microdatas,
  } = props;

  let time = Math.floor(new Date().getTime() / 1000);

  let globalStore = useSelector((state) => state.global);
  let { ga } = globalStore;

  const onHelmetChange = () => {
    if (ga === true) {
      ReactGA.ga(
        "send",
        "pageview",
        window.location.pathname + window.location.search,
        [],
        title
      );
    }
  };
  return (
    <Helmet onChangeClientState={onHelmetChange}>
      <title>{title}</title>
      <meta id="meta-description" name="description" content={description} />
      <meta property="og:url test" content={window.location.href} />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:locale:alternate" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={og_fb_desc} />
      <meta property="og:image" content={`${og_fb_img}?v=${time}`} />
      <meta property="og:image:secure_url" content={og_fb_img} />
      <meta property="og:image:width" content={og_fb_img_width} />
      <meta property="og:image:height" content={og_fb_img_height} />
      <meta property="og:image:alt" content={og_fb_title} />
      <meta property="og:updated_time" content={time} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@GazettedeMonaco" />
      <meta name="twitter:creator" content="@GazettedeMonaco" />
      <meta name="twitter:title" content={og_twitter_title} />
      <meta name="twitter:description" content={og_twitter_desc} />
      <meta name="twitter:image" content={`${og_twitter_img}?v=${time}`} />

      <link rel="canonical" href={canonical} />
      <link rel="alternate" hreflang="x-default" href={canonical} />
      {alternate && (
        <link
          rel="alternate"
          hreflang={alternate.hreflang}
          href={alternate.url}
        />
      )}

      {microdatas && Object.keys(microdatas).length > 0 && (
        <script type="application/ld+json">
          {JSON.stringify(microdatas, null, 2)}
        </script>
      )}

      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      {document.querySelector(".twitter-tweet") && (
        <script
          defer
          async
          src="https://platform.twitter.com/widgets.js"
          type="text/javascript"
        />
      )}
    </Helmet>
  );
};
