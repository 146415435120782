import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonSearch = () => {
  let pageRef = useRef();

  // STORE REDUX
  let header = useSelector((state) => state.header);
  let { height_before_sticky } = header;

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.style.marginTop = `${height_before_sticky}px`;
    }
  }, [height_before_sticky]);

  return (
    <SkeletonTheme>
      <div className="page" ref={pageRef}>
        <div className="top-banner">
          <div className="container">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="content">
          <div className="container">
            <div className="search">
              <div className="main-wrapper">
                <div className="hidden-l hidden-xl">
                  <Skeleton height={50} />
                </div>

                <Skeleton height={100} />

                <div className="results">
                  <Skeleton height={50} />
                </div>

                {/* list de resultats */}
                <div className="list row">
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                </div>
              </div>

              <div className="right-col hidden-s hidden-m">
                <>
                  <Skeleton height={500} />
                  <Skeleton height={500} />
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
