import React from 'react';

// External comps
import { Form } from './Forms/Form';

export const Newsletter = (props) => {
	return (
        <div className="newsletter">
            <Form {...props} />
        </div>
	)
};


// import React, { useEffect, useRef, useState } from 'react';

// // External comps
// import { Form } from './Forms/Form';

// export const Newsletter = (props) => {
//   const modalRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(true);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         // Clicked outside the modal, close it
//         setIsModalOpen(false);
//       }
//     };

//     document.addEventListener('click', handleClickOutside);

//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, []);

//   return (
//     <>
//       {isModalOpen && (
//         <div className="newsletter open" ref={modalRef}>
//           <Form {...props} />
//         </div>
//       )}
//       {!isModalOpen && null}
//     </>
//   );
// };