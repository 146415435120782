import React from 'react';
import { useTranslate } from '../Hooks/useTranslate';


/**
 * @component
 * 	## Renvoie une traduction 
 * @example
 * <GetTranslate keyword="motcle"/>
 * @param {string} keyword mot clé à traduire
 * @returns {string} mot clé traduit suivant langue
 */
export const GetTranslate = ({keyword}) => {
	let [ word ] = useTranslate(keyword);
	return(
		<>{word}</>
	)
};