import { saveState, loadState } from "../../Utils/loadState";
import { UPDATE_LANG } from "../action";

const initialState = {
  lang: loadState("gazette-lang") || "fr",
};

/**
 * LANGUE REDUCER
 *  Gestion du changement de langue
 * @param {*} state
 * @param {*} action
 */
export function langReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LANG:
      saveState("gazette-lang", action.value);
      document.querySelector("html").setAttribute("lang", action.value);
      return { ...state, lang: action.value };
    default:
      return state;
  }
}
