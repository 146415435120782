import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// templates
import { Template404 } from '../Templates/Template404';
import { TemplateHome } from '../Templates/TemplateHome';
import { TemplateSearch } from '../Templates/TemplateSearch';
import { TemplateArticle } from '../Templates/TemplateArticle';
import { TemplateRubrique } from '../Templates/TemplateRubrique';
import { TemplateContenu } from '../Templates/TemplateContenu';
import { TemplateRealestate } from '../Templates/TemplateRealestate';

// Store
import { updateApp } from '../Store/action';

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
	'Template 404': Template404,
	'Template Home': TemplateHome,
	'Template Article': TemplateArticle,
	'Template Search': TemplateSearch,
	'Template Rubrique': TemplateRubrique,
	'Template Content Page': TemplateContenu,
	'Template Liste annonces immobilieres': TemplateRealestate
};

/**
 * @component 
 * @description crer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes 
 */
export function RouteForTemplates(route) {
	let dispatch = useDispatch();
	let { lang } = useSelector(state => state.lang);
	let history = useHistory();
	let { search, hash } = useLocation();

	useEffect(() => {
		if (!route.computedMatch.isExact) {
			history.replace({ pathname: window.pathArticle, search, hash });
		} else {
			history.replace({ pathname: route['path_' + lang], search, hash });
		}
	}, [history, lang]);

	// Is APP ?
	useEffect(()=>{
		hash === '#isMobileApp' ? dispatch(updateApp(true)) :  dispatch(updateApp(false));
	},[dispatch, hash])

	return (
		<Route
			path={route['path_' + lang]}
			name={route.component}
			exact={route.exact}
		
			component={() =>
				React.createElement(DynamicTemplate[route.component], {
					...route
				})}
		/>
	);
}