import { GLOBAL_TITLE } from "../action";

const initialState = {
  page: "",
  title: "",
};

/**
 *  SLUG REDUCER
 *  Gestion des slugs
 * @param {*} state
 * @param {*} action
 */
export function pageReducer(state = initialState, action) {
  switch (action.type) {
    case "pageslug":
      return { ...state, page: action.value };
    case GLOBAL_TITLE:
      return { ...state, title: action.value };
    default:
      return state;
  }
}
