import React, { useState, useEffect, useRef } from "react";
import { generate } from "shortid";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Share } from "../Article/Share";

export const Push = (props) => {
  const {
    categories = [],
    index,
    offer,
    color,
    chapo,
    date,
    day,
    hour,
    title,
    preview_image,
    name,
    type,
    price,
    surface,
  } = props.datas;

  const { h2, sharer, id } = props;

  const [text, setText] = useState(chapo);
  const [ssurface, setSurface] = useState("");
  const [pprice, setPrice] = useState("");
  let [more, setMore] = useState();
  let [toggleTitle, setToggleTitle] = useState(false);

  let refContainer = useRef();
  let clampRef = useRef();
  let chapoRef = useRef();
  let titleRef = useRef();

  useEffect(() => {
    if (refContainer.current && chapoRef.current && clampRef.current) {
      if (text.length > 80) {
        setText(chapoRef.current.innerText.substring(0, 80));
      }
    }

    if (price) {
      setPrice(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
    }

    if (surface) {
      setSurface(
        surface.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
          "m" +
          String.fromCharCode(178)
      );
    }

    if (titleRef && titleRef.current) {
      if (titleRef.current.scrollHeight > titleRef.current.clientHeight) {
        setMore(true);
      }
    }
  }, [refContainer, titleRef, text, props, price, surface]);

  const renderArray = (data, classname) => {
    if (data.link) {
      return classname === "social-network" ? (
        <Link
          to={data.link.url}
          title={data.text}
          className={classname}
          key={generate()}>
          <i className={`icon icon-${data.text.toLowerCase()}`} />
        </Link>
      ) : (
        <span className={classname} key={generate()} style={{ color }}>
          {data.text}
        </span>
      );
    } else {
      return (
        <span className={classname} style={{ color }} key={generate()}>
          {data.text}
        </span>
      );
    }
  };

  const toggleTitleHandler = () => {
    setToggleTitle(!toggleTitle);

    if (titleRef && titleRef.current) {
      let sH = titleRef.current.scrollHeight;

      titleRef.current.style.maxHeight = !toggleTitle ? `${sH}px` : "";
    }
  };

  const renderTitle = () => {
    if (title) {
      if (h2) {
        return (
          <h2>
            <Link
              className="title"
              to={title.link.url}
              target={title.link.target}
              title={title.text}
              dangerouslySetInnerHTML={{ __html: `${title.text}` }}
            />
          </h2>
        );
      } else if (offer) {
        return (
          <a
            className="title"
            href={title.link.url}
            target={title.link.target}
            dangerouslySetInnerHTML={{ __html: `${title.text}` }}
          />
        );
      } else {
        return (
          <span
            className="title"
            dangerouslySetInnerHTML={{ __html: `${title.text}` }}
            ref={titleRef}
          />
        );
      }
    } else {
      return <Skeleton />;
    }
  };

  return (
    <div className="push" data-name={name} data-index={index}>
      {typeof preview_image !== "undefined" ? (
        <span className="image-container">
          {Object.keys(preview_image.url).length !== 0 && (
            <img
              src={
                preview_image.url.main_push
                  ? preview_image.url.main_push
                  : preview_image.url.push
              }
              alt={preview_image.alt && preview_image.alt}
            />
          )}
          <span className="hover" style={{ backgroundColor: color }} />
        </span>
      ) : (
        ""
      )}
      <div className="content">
        {categories.length > 0 ? (
          <div className="categories">
            {categories.map((data, index) =>
              index === 0 ? renderArray(data, index, "category") : null
            )}
          </div>
        ) : null}

        {offer ? (
          <span className="offer" style={{ color: color }}>
            {offer}
          </span>
        ) : null}

        <div className="top-elements" ref={refContainer}>
          {hour && (
            <>
              {day && <small className="day">{day}</small>}
              {day && hour && <span className="coma">,&nbsp;</span>}
              <span className="hour">{hour}</span>
            </>
          )}
          {renderTitle()}

          {more && (
            <button onClick={() => toggleTitleHandler()}>
              <span className={`operator ${toggleTitle ? "less" : "more"}`}>
                <span>{toggleTitle ? "-" : "+"}</span>
              </span>
            </button>
          )}

          {chapo ? (
            <span ref={chapoRef} className="chapo">
              <span dangerouslySetInnerHTML={{ __html: text }}></span>&nbsp;
              <span className="clamp" ref={clampRef} style={{ color: color }}>
                l...l
              </span>
            </span>
          ) : (
            ""
          )}
          {type || surface ? (
            <span className="description">
              {type}
              {surface && `, ${ssurface}`}
            </span>
          ) : (
            ""
          )}
          {price ? <span className="price">{pprice}</span> : ""}
        </div>

        {sharer || day || date ? (
          <div className="bottom-elements">
            {day && <span className="day">{day}</span>}
            {date && <span className="day">{date}</span>}
            {sharer && (
              <div className="social-networks">
                <Share {...props} type="push" />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
