import React from "react";
import { Fade } from "react-awesome-reveal";

export const Image = ({ datas }) => {
  let { image, copyright } = datas;

  const renderHtml = () => {
    return (
      <Fade triggerOnce direction="top">
        <figure className="image-container">
          <img src={image.url} alt={image.alt} title={image.title} />
          {copyright ? <figcaption>{copyright}</figcaption> : ""}
        </figure>
      </Fade>
    );
  };

  return <>{renderHtml()}</>;
};
