import React from 'react';
import ReactDOM from 'react-dom';

export const Modal = ({ modalNLOpen, closeNLModal, toggleGlass, modalLiveOpen, closeLiveModal, children, component, target }) => {
  return modalNLOpen || modalLiveOpen ? ReactDOM.createPortal(
    <React.Fragment>
      <div className="modal-overlay"/>
      <div className={`modal-wrapper ${component ? component : ''}`} aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
            <button type="button" 
              className="modal-close-button" 
              data-dismiss="modal" 
              aria-label="Close" 
              onClick={() => {
                closeNLModal && closeNLModal()
                closeLiveModal && closeLiveModal()
                toggleGlass && toggleGlass(false)
              }}>
              <i className="icon icon-cross" aria-hidden="true"></i>
            </button>
          </div>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>, target ? target : document.body
  ) : null;

} 