import React, { useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { 
	updateGlobalTitle, 
} from '../Store/action';

export const PageTitle = (props) => {
	let { color, title } = props;
	let { page } = useSelector(state => state.page);
	let pageTitleRef = useRef();
	let dispatch = useDispatch();

	useEffect(() => {
		if (pageTitleRef && pageTitleRef.current) {
			dispatch(updateGlobalTitle(pageTitleRef.current));
		}
	}, [pageTitleRef, dispatch])
	
	return(
		<h1 className="cat-title" id={`${page}-anchor`} style={{color: color}} ref={pageTitleRef}>{title}<span className="line" style={{ backgroundColor: color }}></span></h1>
	)
}
