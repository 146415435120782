import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useApi } from "../../Hooks/useApi";
import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";

export const NextPage = ({ parentCallback, next, last, slug, display }) => {
  const [dataFetch, isLoaded] = useApi({
    name: `news/`,
    page: next,
    display: display,
    categories: slug,
  });

  useEffect(() => {
    if (next === last) {
      return parentCallback(true);
    }
  }, [parentCallback, next, last]);

  return isLoaded ? (
    <Fade cascade direction="top" duration="500">
      <main className="row">
        {dataFetch.pushes.map((block) =>
          CreateComponentsByJSON(block, true, true)
        )}
      </main>
    </Fade>
  ) : null;
};
