import React from 'react';

import { Introduction } from '../Components/Article/Introduction';
import { Video } from '../Components/Video';
import { Loader } from '../Components/Loader';
import { Push } from '../Components/Pushes/Push';
import { Banners } from '../Components/Banners';
import { Image } from '../Components/Article/Image';
import { Button } from '../Components/Buttons';
import { ImageCols } from '../Components/Article/ImageCols';
import { ImageText } from '../Components/Article/ImageText';
import { Text } from '../Components/Article/Text';
import { Twitter } from '../Components/Article/Twitter';
import { ArticleSlider } from '../Components/Article/Slider';

/**
 * Déclaration de la corresspondance nom reçu / nom du composant
 * 
 */
const DynamicComponents = {
	introduction: Introduction,
	video: Video,
	push: Push,
	banner: Banners,
	image: Image,
	image_text: ImageText,
	images_2_cols: ImageCols,
	text: Text,
	twitter: Twitter,
	carrousel: ArticleSlider,
	cta: Button
};

/**
 * ## Generation des composants dynamiquement suivant JSON 
 * @param {object} block 
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
export const CreateComponentsByJSON = (block, h2 = true, sharer = false, main_image = false, id = null) => {
	if (typeof DynamicComponents[block.component] !== 'undefined') {
		return React.createElement(DynamicComponents[block.component], {
			key: block._uid,
			h2,
			sharer,
			main_image,
			id,
			...block
		});
	}
	// component doesn't exist yet
	return React.createElement(() => <Loader />, { key: block._uid });
};
