import React from 'react';
import  { Wysiwyg } from './Wysiwyg';
import { Fade } from 'react-awesome-reveal';
import { generate } from 'shortid';

// Hooks
import { useApi } from '../Hooks/useApi';
import { useSelector } from 'react-redux';

export const SocialWall = () => {
    let breakpointStore = useSelector(state => state.breakpoint);
    let [ dataSocial, dataSocialIsLoaded ] = useApi({name:'view-all-social-posts', isSocial: true});
    let isLoaded = dataSocialIsLoaded;

    let { mobile, tablet, desktop, desktopWide } = breakpointStore;

	return (
        <aside className="social-wall">
            {
                isLoaded &&

                <Fade direction="top" duration="500" cascade triggerOnce className="social-wall-list">
                    {dataSocial.slice(0, 6).map(item => {
                        let { author, date, url, network, text, counts = [] } = item;

                        return (
                            <div key={generate()} className={`network ${network}`}>
                                <div className="social-header">
                                    <i className={`icon icon-${network}`}></i><span className="time">{date}</span>
                                </div>
                                <div className="social-content">
                                    <span className="title">La Gazette de Monaco</span>
                                    <span className="author">@{author}</span>
                                    <a href={url} className="text" target="_blank" title={text} rel="noopener noreferrer">
                                        <Wysiwyg>
                                            {
                                                mobile ? `${text.substring(0, 120)}...` :
                                                tablet ? `${text.substring(0, 100)}...` :
                                                desktop ? `${text.substring(0, 100)}...` :
                                                desktopWide ? `${text.substring(0, 150)}...` :
                                                text
                                            }
                                        </Wysiwyg>
                                    </a>
                                </div>

                                <div className="social-footer">
                                    {
                                        Object.entries(counts).map(count => {
                                            return (
                                                count[1] > 0 &&
                                                <span key={generate()}>
                                                    <i className={`icon icon-${count[0]}`}></i>
                                                    {/*{count[1]}*/}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        )
                    })}
                </Fade>
            }
        </aside>
	)
};