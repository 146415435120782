// import React from "react";
// import { useSelector } from "react-redux";

// // External lib
// import ReactGA from "react-ga";

// const Push = ({
//   agency,
//   description,
//   post_date,
//   offer,
//   piece,
//   place,
//   preview_image,
//   price,
//   surface,
//   title,
// }) => {
//   let { ga } = useSelector((state) => state.global);

//   const onClickEvent = () => {
//     if (ga) {
//       ReactGA.event({
//         category: offer,
//         action: "Click",
//         label: title.text,
//       });
//     }
//   };

//   let formattedPrice;
//   if(price === null) {
//     formattedPrice = 'Prix sur demande';
//   } else {
//     formattedPrice = price.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
//   }

//   return (
//     <div className="push" onClick={onClickEvent}>
//       {preview_image.url.length !== 0 && (
//         <span className="image-container">
//           <img src={preview_image.url.push} alt={preview_image.alt} />
//           <span
//             className="hover"
//             style={{ backgroundColor: "rgb(211, 192, 115)" }}></span>
//         </span>
//       )}
//       <div className="content">
//         <div className="top-elements">
//           <div className="type">{offer}</div>
//           <h2>
//             <a
//               className="title"
//               href={title.link.url}
//               target={title.link.target}
//               title={title.text}>
//               {title.text}
//             </a>
//           </h2>
//           <div className="categories">
//             {place && place}
//             {/* Studio and Pieces implementation */}
//             {/* {piece.toLowerCase() === "studio" ? ( */}
//             {piece === "studio" ? (
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: `&nbsp;- ${piece}`,
//                 }}></div>
//             ) : (
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: `&nbsp;- ${piece} <span>pièces</span>`,
//                 }}></div>
//             )}
//             {/* Studio and Pieces implementation */}
//             {surface && (
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: `&nbsp;- ${surface} <span>m<sup>2</sup></span>`,
//                 }}></div>
//             )}
//           </div>

//           {/* {price && <div className="price">{formattedPrice}</div>} */}
//           <div className="price">{formattedPrice}</div>

//           {/* Restrict the text length*/}
//           <p className="chapo">
//             {preview_image.url.length === 0
//               ? description && description.length > 260
//                 ? description
//                     .replace(/^(.{260}[^\s]*).*/, "$1")
//                     .substring(0, 90) && (
//                     <div>
//                       {description
//                         .replace(/^(.{260}[^\s]*).*/, "$1")
//                         .substring(0, 90)}
//                       <span>I…I</span>
//                     </div>
//                   )
//                 : description
//               : description && description.length > 90
//               ? description
//                   .replace(/^(.{90}[^\s]*).*/, "$1")
//                   .substring(0, 90) && (
//                   <div>
//                     {description
//                       .replace(/^(.{90}[^\s]*).*/, "$1")
//                       .substring(0, 90)}
//                     <span>I…I</span>
//                   </div>
//                 )
//               : description}
//             {/* <span>I…I</span> */}
//           </p>
//           {/* Restrict the text length*/}
//         </div>

//         <div className="bottom-elements">
//           {agency && (
//             <div className="agency">
//               <img className="agency" src={agency.logo} alt={agency.name} />
//             </div>
//           )}

//           {post_date && <div className="day">{post_date}</div>}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Push;





// import React from "react";
// import { useSelector } from "react-redux";
// import ReactGA from "react-ga";

// const Push = ({
//   agency,
//   description,
//   post_date,
//   offer,
//   piece,
//   place,
//   preview_image,
//   price,
//   surface,
//   title,
// }) => {
//   let { ga } = useSelector((state) => state.global);

//   const onClickEvent = () => {
//     if (ga) {
//       ReactGA.event({
//         category: offer,
//         action: "Click",
//         label: title.text,
//       });
//     }
//   };

//   // Banner (Position 0) Styles
//   const bannerStyles = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     backgroundColor: "#F0F0F0", // Adjust the background color
//     padding: "20px", // Adjust the padding
//     marginBottom: "20px", // Adjust the margin bottom
//     width: "50%", // Adjust the width
//     height: "auto", // Maintain aspect ratio
//   };

//   // Banner (Position 0) JSX
//   const element0 = (
//     <div className="full-width-banner" style={bannerStyles}>
//       <span className="image-container" style={{width: "100%", // Adjust the width
//             height: "auto",}}>
//         <img
//           src={preview_image.url.push}
//           alt={preview_image.alt}
//           style={{
//             width: "100%", // Adjust the width
//             height: "auto", // Maintain aspect ratio
//           }}
//         />
//         <span
//           className="hover"
//           style={{ backgroundColor: "rgb(211, 192, 115)" }}
//         ></span>
//       </span>
//       <div className="content">
//         {/* The content for position 0 */}
//         <div className="top-elements">
//           <div className="type">{offer}</div>
//           <h2>
//             <a
//               className="title"
//               href={title.link.url}
//               target={title.link.target}
//               title={title.text}
//             >
//               {title.text}
//             </a>
//           </h2>
//           {place && <div className="categories">{place}</div>}
//           {piece === "studio" ? (
//             <div dangerouslySetInnerHTML={{ __html: `&nbsp;- ${piece}` }}></div>
//           ) : (
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: `&nbsp;- ${piece} <span>pièces</span>`,
//               }}
//             ></div>
//           )}
//           {surface && (
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: `&nbsp;- ${surface} <span>m<sup>2</sup></span>`,
//               }}
//             ></div>
//           )}
//           {price && <div className="price">{price}</div>}
//           {preview_image.url.length === 0 ? (
//             <p className="chapo">
//               {description && description.length > 260 ? (
//                 <div>
//                   {description.replace(/^(.{260}[^\s]*).*/, "$1").substring(0, 90)}
//                   <span>I…I</span>
//                 </div>
//               ) : (
//                 description
//               )}
//             </p>
//           ) : (
//             <p className="chapo">
//               {description && description.length > 90 ? (
//                 <div>
//                   {description.replace(/^(.{90}[^\s]*).*/, "$1").substring(0, 90)}
//                   <span>I…I</span>
//                 </div>
//               ) : (
//                 description
//               )}
//             </p>
//           )}
//         </div>
//       </div>
//     </div>
//   );

//   // Other Elements (Position 1 and Onwards) JSX
//   const otherElements = (
//     <div
//       className={`push ${preview_image.url.length !== 0 ? "horizontal" : ""}`}
//       onClick={onClickEvent}
//       style={{
//         // Add additional styles for the non-banner elements if needed
//       }}
//     >
//       <div className="content">
//         <div className="top-elements">
//           <div className="type">{offer}</div>
//           <h2>
//             <a
//               className="title"
//               href={title.link.url}
//               target={title.link.target}
//               title={title.text}
//             >
//               {title.text}
//             </a>
//           </h2>
//           {place && <div className="categories">{place}</div>}
//           {piece === "studio" ? (
//             <div dangerouslySetInnerHTML={{ __html: `&nbsp;- ${piece}` }}></div>
//           ) : (
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: `&nbsp;- ${piece} <span>pièces</span>`,
//               }}
//             ></div>
//           )}
//           {surface && (
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: `&nbsp;- ${surface} <span>m<sup>2</sup></span>`,
//               }}
//             ></div>
//           )}
//         </div>
//         <div className="bottom-elements">
//           {agency && (
//             <div className="agency">
//               <img className="agency" src={agency.logo} alt={agency.name} />
//             </div>
//           )}
//           {post_date && <div className="day">{post_date}</div>}
//         </div>
//       </div>
//     </div>
//   );

//   return (
//     <>
//       {element0}
//       {otherElements}
//     </>
//   );
// };

// export default Push;




// double image at the beginning code
import React from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

const Push = ({
  agency,
  description,
  post_date,
  offer,
  piece,
  place,
  preview_image,
  price,
  surface,
  title,
  index,
  isDouble
}) => {
  const { ga } = useSelector((state) => state.global);

  const onClickEvent = () => {
    if (ga) {
      ReactGA.event({
        category: offer,
        action: "Click",
        label: title.text,
      });
    }
  };
  console.log('price', price);

  let formattedPrice;
  if(price === null) {
    formattedPrice = 'Prix sur demande';
  } else {
    formattedPrice = price.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return (
    <div className="push" onClick={onClickEvent} style={{ width: index === 0 && isDouble===1 ? "64%" : "" }}>          
    {preview_image.url.length !== 0 && (
        <span className="image-container">
          <img src={preview_image.url.push} alt={preview_image.alt} />
          <span
            className="hover"
            style={{ backgroundColor: "rgb(211, 192, 115)" }}></span>
        </span>
      )}
      <div className="content">
        <div className="top-elements">
          <div className="type">{offer}</div>
          <h2>
            <a
              className="title"
              href={title.link.url}
              target={title.link.target}
              title={title.text}>
              {title.text}
            </a>
          </h2>
          <div className="categories">
            {place && place}
            {piece === "studio" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: `&nbsp;- ${piece}`,
                }}></div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: `&nbsp;- ${piece} <span>${piece<= 1 ? 'pièce' : 'pièces'}</span>`,
                }}></div>
            )}
            {surface && (
              <div
                dangerouslySetInnerHTML={{
                  __html: `&nbsp;- ${surface} <span>m<sup>2</sup></span>`,
                }}></div>
            )}
          </div>

          {/* {price && <div className="price">{formattedPrice}</div>} */}
          <div className="price">{formattedPrice}</div>

          <p className="chapo">
            {preview_image.url.length === 0
              ? description && description.length > 260
                ? description
                    .replace(/^(.{260}[^\s]*).*/, "$1")
                    .substring(0, 90) && (
                    <div>
                      {description
                        .replace(/^(.{260}[^\s]*).*/, "$1")
                        .substring(0, 90)}
                      <span>I…I</span>
                    </div>
                  )
                : description
              : description && description.length > 90
              ? description
                  .replace(/^(.{90}[^\s]*).*/, "$1")
                  .substring(0, 90) && (
                  <div>
                    {description
                      .replace(/^(.{90}[^\s]*).*/, "$1")
                      .substring(0, 90)}
                    <span>I…I</span>
                  </div>
                )
              : description}
          </p>
        </div>

        <div className="bottom-elements">
          {agency && (
            <div className="agency">
              <img className="agency" src={agency.logo} alt={agency.name} />
            </div>
          )}

          {/* {post_date && <div className="day">{post_date}</div>} */}
        </div>
      </div>
    </div>
  );
};

export default Push;