// import React, { useRef, useState, useEffect } from "react";
// import Push from "./Push";
// import { ButtonSeeMore } from "../Buttons";
// import { NextPage } from "./NextPage";

// // Hooks
// import { useTranslate } from "../../Hooks/useTranslate";

// export const Results = ({
//   newContent,
//   setNewContent,
//   isLast,
//   setIsLast,
//   results,
//   last,
//   next,
//   offerParams,
//   filterLast,
//   filterCurrent,
// }) => {
//   let tradSeeMore = useTranslate("moregoods");
//   let noResults = useTranslate("noresult");

//   let [nbitem, setNbItem] = useState(0);
//   let newContentRef = useRef("");

//   const [isClicked, setIsClicked] = useState(false);

//   useEffect(() => {
//     if (
//       window.persistentNext < 1 ||
//       window.persistentNext === null ||
//       window.persistentNext === ""
//     ) {
//       setIsLast(true);
//     }

//     return function cleanup() {
//       window.persistentNext = undefined;
//       window.persistentData = undefined;
//     };
//   }, [next, setIsLast]);

//   const parentCallback = (boolean) => {
//     setIsLast(boolean);
//   };

//   const CallNextPage = () => {
//     let nextpage = (
//       <NextPage
//         parentCallback={parentCallback}
//         next={window.persistentNext}
//         last={last}
//         display="6"
//         offerParams={offerParams}
//       />
//     );
//     window.persistentData.push(nextpage);
//     setNewContent(window.persistentData);
//     setNbItem(window.persistentData.length);
//     window.persistentNext++;

//     setTimeout(() => {
//       if (newContentRef && newContentRef.current) {
//         window.scrollTo(0, newContentRef.current.offsetTop - 156);
//       }
//     }, 1000);

//     setIsClicked(true);
//   };

//   return (
//     <>
//       <div className="realestates-layout">
//         {results &&
//           results !== null &&
//           (results.length > 0 ? (
//             results
//               .slice(0, 6)
//               .map(({ datas, _uid }) => <Push {...datas} key={_uid} />)
//           ) : (
//             <div>{noResults}</div>
//           ))}
//         {newContent &&
//           newContent.map((item, id) => (
//             <div
//               ref={newContentRef}
//               className={`newcontent-${id + 1}`}
//               data-nbitem={nbitem}
//               key={`newcontent-${id}`}>
//               {item}
//             </div>
//           ))}
//       </div>

//       {/* Plus de biens buttom correction      */}
//       {!isLast && filterCurrent < filterLast && !isClicked ? (
//         <div className="seemore">
//           <ButtonSeeMore action={() => CallNextPage()}>
//             {tradSeeMore}
//             <span className="more">+</span>
//           </ButtonSeeMore>
//         </div>
//       ) : null}
//     </>
//   );
// };


// import React, { useRef, useState, useEffect } from "react";
// import Push from "./Push";
// import { ButtonSeeMore } from "../Buttons";
// import { NextPage } from "./NextPage";

// // Hooks
// import { useTranslate } from "../../Hooks/useTranslate";

// export const Results = ({
//   newContent,
//   setNewContent,
//   isLast,
//   setIsLast,
//   results,
//   last,
//   next,
//   offerParams,
//   filterLast,
//   filterCurrent,
// }) => {
//   let tradSeeMore = useTranslate("moregoods");
//   let noResults = useTranslate("noresult");

//   let [nbitem, setNbItem] = useState(0);
//   let newContentRef = useRef("");
//   let [displayCount, setDisplayCount] = useState(6);
//   const [isClicked, setIsClicked] = useState(false);

//   useEffect(() => {
//     if (
//       window.persistentNext < 1 ||
//       window.persistentNext === null ||
//       window.persistentNext === ""
//     ) {
//       setIsLast(true);
//     }

//     return function cleanup() {
//       window.persistentNext = undefined;
//       window.persistentData = undefined;
//     };
//   }, [next, setIsLast]);

//   const parentCallback = (boolean) => {
//     setIsLast(boolean);
//   };

//   const CallNextPage = () => {
//     let nextpage = (
//       <NextPage
//         parentCallback={parentCallback}
//         next={window.persistentNext}
//         last={last}
//         display={displayCount}
//         offerParams={offerParams}
//       />
//     );
//     window.persistentData.push(nextpage);
//     setNewContent([...window.persistentData]);
//     setNbItem(window.persistentData.length);
//     window.persistentNext++;

//     setIsClicked(true);
//     setDisplayCount(displayCount + 6);
//   };

//   return (
//     <>
//       <div className="realestates-layout">
//         {results &&
//           results !== null &&
//           (results.length > 0 ? (
//             results
//               .slice(0, displayCount)
//               .map(({ datas, _uid }, index) => (
//                 <Push {...datas} key={_uid} index={index} />
//               ))
//           ) : (
//             <div>{noResults}</div>
//           ))}
//         {newContent &&
//           newContent.map((item, id) => (
//             <div
//               ref={newContentRef}
//               className={`newcontent-${id + 1}`}
//               data-nbitem={nbitem}
//               key={`newcontent-${id}`}
//             >
//               {item}
//             </div>
//           ))}
//       </div>

//       {/* Plus de biens button correction      */}
//       {!isLast && filterCurrent < filterLast && !isClicked ? (
//         <div className="seemore">
//           <ButtonSeeMore action={() => CallNextPage()}>
//             {tradSeeMore}
//             <span className="more">+</span>
//           </ButtonSeeMore>
//         </div>
//       ) : null}
//     </>
//   );
// };



import React, { useState, useEffect, memo } from "react";
import Push from "./Push";
import { ButtonSeeMore } from "../Buttons";
import { NextPage } from "./NextPage";
import { useTranslate } from "../../Hooks/useTranslate";

export const Results = memo(({ results }) => {
  const [displayCount, setDisplayCount] = useState(6);
  const [shuffledResults, setShuffledResults] = useState([]);
  const tradSeeMore = useTranslate("moregoods"); // get the translated text

  useEffect(() => {
    if (results) {
      // Shuffle the initial results
      const initialShuffledResults = shuffleArray(results);
      setShuffledResults(initialShuffledResults);
    }
  }, [results]);

  // Function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    // Find an item where datas.isDouble == 1
    const doubleIndex = array.findIndex((item) => item.datas.isDouble == 1);

    // If found, swap with the 0th item
    if (doubleIndex !== -1) {
      [array[0], array[doubleIndex]] = [array[doubleIndex], array[0]];
    }

    return array;
  };

  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 6);
  
    // Calculate the range of new results to fetch
    const startIndex = displayCount;
    const endIndex = displayCount + 6;
  
    // Slice the results array to get new items
    const newResults = results.slice(startIndex, endIndex);
  
    // Shuffle the new results
    const shuffledNewResults = shuffleArray(newResults);
  
    // Filter out duplicates from the shuffled new results
    const uniqueNewResults = shuffledNewResults.filter(
      (newResult) => !shuffledResults.some((existingResult) => existingResult._uid === newResult._uid)
    );
  
    // Concatenate unique new results with existing shuffled results
    setShuffledResults((prevResults) => [...prevResults, ...uniqueNewResults]);
  };

  // Check if results is defined
  if (!results) {
    return null;
  }

  return (
    <>
      <div className="realestates-layout">
        {shuffledResults.slice(0, displayCount).map(({ datas, _uid }, index) => (
          <Push {...datas} key={_uid} index={index} />
        ))}
      </div>

      {displayCount < results.length && (
        <div className="seemore">
          <ButtonSeeMore action={handleSeeMore}>
            {tradSeeMore} {/* use the translated text */}
            <span className="more">+</span>
          </ButtonSeeMore>
        </div>
      )}
    </>
  );
});