/**
 * REDUCER DE LA CONFIGURATION DU HEADER
 * @param {*} state
 * @param {*} action
 */

const initialState = {
  sticky: false,
};

export function headerReducer(state = initialState, action) {
  switch (action.type) {
    case "STICKY":
      return { ...state, sticky: action.value };
    default:
      return state;
  }
}
