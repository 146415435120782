import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// GA
import ReactGA from 'react-ga';

// Hooks
import { useTranslate } from '../Hooks/useTranslate';
import { useSelector } from 'react-redux';

export const SearchBar = ({mobile, closeMenu, closeBurger, toggleSearchBar, page}) => {
	// REDUX
	let { routes, ga } = useSelector(state => state.global);
	let routeSearch = routes.find(it => it.component === 'Template Search'); // recupére les données du search dans routes
	let { lang } = useSelector(state => state.lang);

	// STATES
	let [ search, setSearch ] = useState('');
	let [tradPlaceholder] = useTranslate("searchPlaceholder");
	
	const refInput = useRef('');
	const history = useHistory();
	
	const retournePathOfSearch = function(){
		if (lang === "fr"){
			return routeSearch.path
		}
		else{
			return routeSearch.path_en
		}
	}
	const adressSearch = retournePathOfSearch();
	
	const refForm = useRef();
	const refEvent = useRef(false);
	
	const redirectToSearch = (e) => {
		e.preventDefault();
		// refForm.current.reset();
		if (closeMenu && mobile) {
			closeBurger();
			closeMenu();
		}
		history.push({
			pathname: adressSearch,
			search :`?=${search}`
		});
		
		let elementToScroll = document.querySelector('.top-banner');
		elementToScroll && window.scrollTo({top: elementToScroll.offsetHeight, left: 0, behavior: 'smooth' });
	};

	const defineSearch = (e) => {
		e.preventDefault();
		setSearch(e.target.value)
	};

	const handleClick = () => {
		if (!mobile) {
			toggleSearchBar();
		}
		if (page === 'homepage' && ga) {
			if (!refEvent.current) {
				ReactGA.event({
					category: 'Search',
					action: 'Clicked search',
					label: 'Search'
				});
			  
				refEvent.current = true;
			}
		}
		refInput.current.focus();
	}

	return (
		<form onSubmit={redirectToSearch} ref={refForm} className="searchbar form">
			<div className="field">
				<input
					type="text"
					placeholder={tradPlaceholder}
					onChange={defineSearch}
					ref={refInput}
				/>
				{
					refInput.current.selectionStart > 0 ?
					<button className="icon icon-search" type='submit' onClick={redirectToSearch} /> :
					<button className="icon icon-search" type='button' onClick={handleClick} />
				}
			</div>
		</form>
	);
};
