// import React, { useState, useEffect } from "react";
import { Carousel } from "3d-react-carousal";
import { TemplateArticle } from "../../Templates/TemplateArticle";
var React = require("react");

export const Carousel23 = (props) => {

  // const CustomArrow = ({ onClick, iconClassName }) => {
  //   return (
  //     <div className="custom-arrow" onClick={onClick}>
  //       {/* <span className={iconClassName}></span> */}
  //       <i className={iconClassName}></i>
  //     </div>
  //   );
  // };

  let slides = [];

  for (var par in props) {
    slides.push(
      <figure className="image-container">
        <img src={props[par][0]} style={{objectFit: 'contain', height: '100%', width: '100%'}} />
        <figcaption>{props[par][1]}</figcaption>
      </figure>
    );
  }

  if (slides.length > 0) {
    return (
      <Carousel
        key="1"
        slides={slides}
        autoplay={true}
        interval={3000}
        arrows={true}
        prevArrow={true}
        nextArrow={true}
        ></Carousel>
    );
  } else {
    return (
      <Carousel
        key="2"
        slides={slides}
        style={{
          display: "none",
          visibility: "none",
        }}
        autoplay={false}
        interval={false}
        arrows={false}

        ></Carousel>
    );
  }
};
