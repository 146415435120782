import React from "react";
import { Wysiwyg } from "../Wysiwyg";
import { Fade } from "react-awesome-reveal";

export const Twitter = ({ datas }) => {
  let { text } = datas;
  return (
    <Fade triggerOnce direction="top" className="text">
      <Wysiwyg>{text}</Wysiwyg>
    </Fade>
  );
};
