import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// External lib
import ReactGA from "react-ga";
import { generate } from "shortid";

export const Breadcrumbs = React.memo((props) => {
  let { breadcrumbs } = props;
  let { ga } = useSelector((state) => state.global);

  // GA
  useEffect(() => {
    if (ga) {
      ReactGA.set({
        "@type": "BreadcrumbList",
        itemListElement: [
          breadcrumbs.map((link, index) => {
            return {
              "@type": "ListItem",
              position: index + 1,
              name: link.text,
              item: link.url,
            };
          }),
        ],
      });
    }
  }, [breadcrumbs, ga]);

  return (
    <Fade direction="top" triggerOnce cascade className="breadcrumbs">
      {breadcrumbs &&
        breadcrumbs.map((link) => (
          <ReactGA.OutboundLink
            key={generate()}
            eventLabel={link.text}
            to={`${link.url}${
              link.slug ? `#rubrique-${link.slug}-anchor` : ""
            }`}
            title={link.text}
            dangerouslySetInnerHTML={{ __html: link.text }}
          />
        ))}
    </Fade>
  );
});
