import React from "react";
import { Fade } from "react-awesome-reveal";

// Components
import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";

// Hooks
import { useApi } from "../../Hooks/useApi";

export const MostPopular = ({ _uid }) => {
  let [dataFetch, isLoaded] = useApi({
    name: "news/most-popular",
    _uid,
    light: true,
  });
  let { pushes = [], title } = dataFetch;

  return (
    <Fade triggerOnce direction="top" className="most-popular">
      {isLoaded ? (
        <>
          <h2 className="block-title">{title}</h2>
          {pushes.length > 0
            ? pushes.slice(0, 5).map((block) => {
                return CreateComponentsByJSON(block);
              })
            : null}
        </>
      ) : null}
    </Fade>
  );
};
