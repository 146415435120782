import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sticky from 'react-sticky-fill'
import { Fade } from 'react-awesome-reveal';

// Components
import { Metatags } from '../Components/Metatags';
import { CreateComponentsByJSON } from '../Utils/CreateComponentsByJSON'
import { useLocation } from 'react-router-dom';
import { SearchBar } from '../Components/SearchBar';
import { Live } from '../Components/Pushes/Live';
import { Banners } from '../Components/Banners';
import { Loader } from '../Components/Loader';
import { SkeletonSearch } from '../Components/Skeletons/SkeletonSearch';

// Hooks
import { useApi } from '../Hooks/useApi';
import { useTranslate } from '../Hooks/useTranslate';

export const TemplateSearch = ({ _uid }) => {
	let location = useLocation();
	let pageRef = useRef();
	let dispatch = useDispatch();
	let { global } = useSelector(state => state.global);
	
	let [nbOfResults, setNbOfResults ] = useState(null);
	let [termOfSearch, setTermOfSearch] = useState(location.search.substr(2));

	let [ dataSearch, dataSearchIsLoaded ] = useApi({name:"search", termOfSearch, _uid});

	let [ dataBanners, dataBannersIsLoaded ] = useApi({name:`${global.banners.search}`, isBanner: true});
	
	let isLoaded = dataSearchIsLoaded && dataBannersIsLoaded;
	
	let searchResultTitle = useTranslate('searchResultTitle');
	let noresult = useTranslate('noresult');

	let { metas, pushes = [], canonical, alternate } = dataSearch;

	// A CHAQUE UPDATE DE LA RECHERCHE
	useEffect(()=>{
		let nombreDeResultats = pushes.length;

		setNbOfResults(nombreDeResultats);
	},[dataSearch, pushes.length])

	// Dispatch
	useEffect(()=>{
		dispatch({type:"pageslug", value: 'search'});
		const scrollHandler = () => {
			if (pageRef.current) {
				dispatch({type:"pagedelta", value: document.querySelector('.page').getBoundingClientRect().top})
			}
		}
		window.addEventListener('scroll', scrollHandler);
		// clean listener
		return() => {
			window.removeEventListener('scroll', scrollHandler);
		}
	},[dispatch, global.banners, isLoaded])

	// A CHAQUE NOUVELLE RECHERCHE : 
	useLayoutEffect(()=>{
		setTermOfSearch(location.search.substr(2))
	}, [termOfSearch, location])

	return (
		<>
			{ isLoaded ? 
				<>
					<Metatags canonical={canonical} alternate={alternate} {...metas} />
					<div className="page" ref={pageRef}>
						<Banners {...dataBanners } name="billboard" />
						<div className="content">
							<div className="container">
								<div className="search">
									<div className="main-wrapper">								
										<div className='hidden-l hidden-xl'>
											<SearchBar />
										</div>
									
										<div className="title">{searchResultTitle} : <span className="red">{termOfSearch}</span></div>

										<div className="results">
											{ isLoaded ? 
												nbOfResults > 0 ?  
												null
												: 
												<>{noresult} - <span className="red">{termOfSearch ? ` "${termOfSearch}"`: ''}</span> </> : null}
										</div>
										

										{/* list de resultats */}
										{isLoaded ? 
											nbOfResults > 0 ?
												<Fade triggerOnce cascade> 
													<main className="list row">
														{pushes.map( res =>  CreateComponentsByJSON(res, true, true))} 
													</main>
												</Fade>
											: null
										: <Loader />
										}	
										
									</div>
										
									<div className="right-col hidden-s hidden-m">
										<Live _uid={_uid} />
										<Sticky className="sticky">
											<Banners {...dataBanners} name="right_halfpage" />
										</Sticky>
									</div>
								</div>
							</div>
						</div>			
					</div>
				</>
				: <SkeletonSearch />
			}
		</>
	)
}