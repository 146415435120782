import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactDOM from 'react-dom';

// External lib
import ReactGA from "react-ga";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import Axios from "axios";

// Hooks
import { apiLaravel } from "../Hooks/apiLaravel";

export const Banners = (datas = []) => {
  SwiperCore.use([Autoplay, EffectFade]);

  let { name, banners = [] } = datas;
  let { page } = useSelector((state) => state.page);
  let { ga } = useSelector((state) => state.global);
  let { mobile, tablet } = useSelector((state) => state.breakpoint);
  let [render, setRender] = useState(false);
  let res = useRef();
  let [clickCount, setClickCount] = useState(0);
  const [data, setData] = useState([]);
  
  const [lastSegment, setLastSegment] = useState('')
  const [url, setUrl] = useState(window.location.pathname);  

  function category_EN_TO_FR(category) {
    var categories = {
      "politics": "politique",
      "economics": "economie",
      "society": "societe",
      "real-estate": "immobilier",
      "art-and-culture": "art-culture",
      "sports": "sport",
      "mareterra": "mareterra",
      "town-planning": "urbanisme",
      "environment": "environnement",
      "health": "sante",
      "technology": "technologie",
      "art-of-living": "art-de-vivre",
      "pratical-info": "info-pratique",
    };

    if (categories.hasOwnProperty(category)) {
      return categories[category];
    } else {
      return "Translation not found";
    }
  }
  
  useEffect(() => {
    const handleURLChange = () => {
      let currentUrl = window.location.pathname;
      setUrl(currentUrl);
      let parts = currentUrl.split("/");
      setLastSegment(parts[parts.length - 1]);
      if(currentUrl == "/" || currentUrl == "/en/") {
        setLastSegment("home")
      } else if(parts[1] == "en") {
        setLastSegment(category_EN_TO_FR(parts[parts.length - 1]));
      }
      
    };

    // Set the initial last segment
    handleURLChange();

    // Listen for URL changes
    const handlePopstate = () => {
      handleURLChange();
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      // Clean up the event listener
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  
  // useEffect(() => {   
  const onClickEvent = (type, dataclick) => {
    if (ga) {
      ReactGA.event({
        category: "Banner",
        action: "Click",
        label: `${page} - ${type}`,
      });
    }

      
    setClickCount(1);

    async function fetchData() {
      try {
        const response = await apiLaravel.get('/pub-click/', {
          params: {
            dataclick_id: dataclick.id,
            dataclick_client: dataclick.client,
          },
        });
        setData(response.data);
      } catch (error) {
        // console.error(error);
      }
  
    }
    fetchData();
    // }, []);

  };

  // }, []);
  // Filter by breakpoints
  let firstLevel = banners.filter((el) => name === el.datas.type);

  // HTML Object
  // 2 cases : carousel or not
  const renderHTML =
    name === "right_halfpage" || name === "left_halfpage" ? (
      <Swiper
        autoplay={true}
        loop={true}
        effect="fade"
        onSwiper={(e) => {
          if (e.slides[e.activeIndex]) {
            e.slides[e.activeIndex].style.opacity = 1;
          }
        }}
        onSlideChange={(e) => {
          let previousIndex = e.previousIndex - 1;
          if (previousIndex === -1) {
            previousIndex = 0;
          }

          if (e.slides[e.previousIndex]) {
            e.slides[e.previousIndex].style.opacity = 0;
          }
          if (e.slides[e.activeIndex]) {
            e.slides[e.activeIndex].style.opacity = 1;
          }
        }}>
        {firstLevel.map((el) => {
          let { link, preview_image = [], delay, type = []} = el.datas;
          let dataclick = el;
          
          // Filter by breakpoints
          let secondLevel = preview_image.filter(
            (el) => res.current === el.res
          );

          // Rendering
          if (preview_image.length > 0) {
            return secondLevel.map((image, index) => {
              return (
                <SwiperSlide
                  key={image}
                  className="banner"
                  data-banner={type}
                  data-type={image.type}
                  data-swiper-autoplay={delay * 1000}>
                  <a
                    href={link.url}
                    target={link.target}
                    onClick={() => onClickEvent(type, dataclick)}>
                    <img key={index} src={image.url} alt={image.alt} />
                  </a>
                </SwiperSlide>
              );
            });
          }
        })}
      </Swiper>
    ) : (
      firstLevel.map((el) => {
        let { link, preview_image = [], type = [] } = el.datas;
        let dataclick = el;

        // Filter by breakpoints
        let secondLevel = preview_image.filter((el) => res.current === el.res);

        // Rendering
        if (preview_image.length > 0) {
          if (type === "billboard") {
            return secondLevel.map((image, index) => {
              return (
                <div className="top-banner" key={image}>
                  <div className="container">
                    <a
                      className="banner"
                      data-type={image.type}
                      href={link.url}
                      target={link.target}
                      onClick={() => onClickEvent(type, dataclick)}>
                      <img key={index} src={image.url} alt={image.alt} />
                    </a>
                  </div>
                </div>
              );
            });
          } else {
            // console.log("el.category === lastSegment && type", el.category, lastSegment, type)
            if(el.category === lastSegment && type === "event") {
              // if (type === "event") {
              const mainAppElement = document.getElementById('main-app');
              if (mainAppElement) {
                mainAppElement.className += ' exist-event';
              }
              // }
              return secondLevel.map((image, index) => {
                return (
                  <a
                    key={image}
                    className="banner"
                    data-banner={type}
                    data-type={image.type}
                    href={link.url}
                    target={link.target}
                    onClick={() => onClickEvent(type, dataclick)}>
                    <img key={index} src={image.url} alt={image.alt} />
                  </a>
                );
              });
            } else if (type !== "event"){
              return secondLevel.map((image, index) => {
                return (
                  <a
                    key={image}
                    className="banner"
                    data-banner={type}
                    data-type={image.type}
                    href={link.url}
                    target={link.target}
                    onClick={() => onClickEvent(type, dataclick)}>
                    <img key={index} src={image.url} alt={image.alt} />
                  </a>
                );
              });
            } else {
              return (
                <div></div>
              )
            }
          }
        }
      })
    );

  // Rendering condition
  useEffect(() => {
    setRender(true);
  }, [banners, render]);

  // Res
  useEffect(() => {
    if (mobile) {
      res.current = "s";
    } else if (tablet) {
      res.current = "m";
    } else {
      res.current = "l";
    }

  }, [res, mobile, tablet]);
  return <aside className="banners">{render && renderHTML}</aside>;
};

