import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";

// Components
import { Metatags } from "../Components/Metatags";
import { Banners } from "../Components/Banners";
import { PageTitle } from "../Components/PageTitle";
import { Filter } from "../Components/RealEstates/Filter";
import { PriceFilter } from "../Components/RealEstates/PriceFilter";
import { Results } from "../Components/RealEstates/Results";
import { Live } from "../Components/Pushes/Live";
import { Breadcrumbs } from "../Components/Breadcrumbs";
import { Newsletter } from "../Components/Newsletter";
import { SkeletonPage } from "../Components/Skeletons/SkeletonPage";

// External lib
import ReactGA from "react-ga";

// Hooks
import { useApi } from "../Hooks/useApi";

export const TemplateRealestate = React.memo(({ _uid, category }) => {
  let [dataRealestates, dataRealestatesIsLoaded] = useApi({
    name: "realestate/list",
    display: 6,
    _uid,
  });
  let [filterLast, setFilterLast] = useState("");
  let [filterCurrent, setFilterCurrent] = useState("");
  let [newContent, setNewContent] = useState(null);
  let [isLast, setIsLast] = useState(false);

  let paramaters = "";
  const [offerParams, setOfferParams] = useState("");

  let [dataBanners, dataBannersIsLoaded] = useState();
  const prevData = useRef({ dataBanners }).current;

  // get realestates from api
  const [dataOffer, offerIsLoaded] = useApi({
    name: "realestate/list",
    display: 6,
    offerParams,
  });

  let { pushes, filters } = dataOffer;

  let { global, ga } = useSelector((state) => state.global);
  let {
    metas,
    title,
    subtitle,
    seo_link,
    next,
    last,
    is_bloc_newsletter_subscription,
  } = dataRealestates;
  window.persistentNext =
    typeof window.persistentNext !== "undefined" ? window.persistentNext : next;
  window.persistentData =
    typeof window.persistentData !== "undefined" ? window.persistentData : [];
  let history = useHistory();
  let { pathname, hash } = useLocation();
  let url = pathname.split("/").pop();

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  let isLoaded = dataRealestatesIsLoaded && dataBannersIsLoaded;
  let pageRef = useRef();
  let dispatch = useDispatch();

  // select open state
  let [open, setOpen] = useState(null);

  // INIT ITEMS STATES
  const [items, setItems] = useState({
    items: null,
    results: null,
    filters: null,
    filtersChecked: null,
    profileActive: false,
  });

  // KEEP CHECKBOXES STATE UP TO DATE
  const handleInputChange = (list) => {
    let inputsChecked = list.choices.filter((item) => item.isChecked === true);
    let cat = list.choices[0].category;
    let prevState = items.filtersChecked;

    setItems({
      ...items,
      filtersChecked: {
        ...prevState,
        [cat]: inputsChecked,
      },
    });
  };

  // KEEP RANGE STATE UP TO DATE
  const handleRangeChange = (list) => {
    let prevState = items.filtersChecked;

    setItems({
      ...items,
      filtersChecked: {
        ...prevState,
        price_min: [
          {
            category: "price_min",
            value: list.price_min,
          },
        ],
        price_max: [
          {
            category: "price_max",
            value: list.price_max,
          },
        ],
      },
    });
  };

  // BUILD QUERY TO FILTER RESULTS
  const handleFilter = () => {
    if (items.filtersChecked !== null) {
      paramaters = "";
      for (const cat in items.filtersChecked) {
        let listCat = "";

        if (items.filtersChecked[cat].length > 0) {
          items.filtersChecked[cat].map(({ value }) => {
            listCat += "," + value;
          });
          paramaters += "&" + cat + "=" + listCat.substr(1, listCat.length);
        }
      }
      setOfferParams(paramaters);

      // On vide le niveau de page du load more
      setNewContent("");
      setIsLast(false);
      window.persistentNext = 2;
      window.persistentData = [];
    }
  };

  // api call
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFetch = await Axios.get(
          `${process.env.REACT_APP_APP}${global.banners.rubriques[category]}`,
          { cancelToken: source.token }
        );
        if (prevData.dataFetch !== dataFetch) {
          dataBannersIsLoaded(dataFetch.data);
        }
      } catch (err) {
        // console.log(err)
      }
    };

    fetchData();

    return () => {
      source.cancel();
      prevData.dataBanners = dataBanners;
    };
  }, [global.banners.rubriques, category, prevData]);

  // GA
  useEffect(() => {
    // if (isLoaded && ga) {
    // 	ReactGA.set({
    // 		"@context": "https://schema.org",
    // 		"@type": "Category",
    // 		"title": title,
    // 		"url": seo_link.canonical
    // 	})
    // }

    if (pushes) {
      let categories = {
        offer: [],
        type: [],
        place: [],
        piece: [],
        price: [],
        agence: [],
      };

      setItems({
        items: pushes,
        results: pushes,
        filters: filters,
        filtersChecked: categories,
        profileActive: false,
      });
    }
  }, [isLoaded, ga, seo_link, title]);

  const [microdatas, setMicrodatas] = useState({});
  useEffect(() => {
    if (isLoaded) {
      setMicrodatas({
        "@context": "https://schema.org",
        "@type": "Category",
        title: title,
        url: seo_link.canonical,
      });
    }
  }, [isLoaded]);

  // Dispatch
  useEffect(() => {
    dispatch({ type: "pageslug", value: title });
  }, [dispatch, global.banners, title, isLoaded]);

  // replace url
  useEffect(() => {
    if (!url) history.replace({ pathname: pathname.replace(/\/$/, "") });
  }, [hash, history, pathname, url]);

  useEffect(() => {
    handleFilter();
  }, [items]);

  useEffect(() => {
    setItems({
      ...items,
      items: pushes,
      results: pushes,
      filters: filters,
    });
  }, [offerIsLoaded]);

  useEffect(() => {
    setFilterCurrent(dataOffer.current);
    setFilterLast(dataOffer.last);
  }, [dataOffer, filterLast, filterCurrent]);

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...seo_link} {...metas} microdatas={microdatas} />
          <div className="page" ref={pageRef}>
            <Banners {...dataBanners} name="billboard" />
            <div className="content">
              <div className="container">
                <div className="rubrique">
                  <Breadcrumbs {...dataRealestates} />
                  <div className="main-wrapper">
                    <div className="main-content realestates-content">
                      <PageTitle {...dataRealestates} />

                      <p className="realestates-intro">{subtitle}</p>

                      {items.filters && items.filters !== null && (
                        <div className="filters">
                          <Filter
                            {...items.filters[0]}
                            key={items.filters.slug}
                            cat="offer"
                            setOpen={setOpen}
                            current={open}
                            handleInputChange={handleInputChange}
                          />
                          <Filter
                            {...items.filters[1]}
                            key={items.filters.slug}
                            cat="type"
                            setOpen={setOpen}
                            current={open}
                            handleInputChange={handleInputChange}
                          />
                          <Filter
                            {...items.filters[2]}
                            key={items.filters.slug}
                            cat="place"
                            setOpen={setOpen}
                            current={open}
                            handleInputChange={handleInputChange}
                          />
                          <Filter
                            {...items.filters[3]}
                            key={items.filters.slug}
                            cat="piece"
                            setOpen={setOpen}
                            current={open}
                            handleInputChange={handleInputChange}
                          />
                          <PriceFilter
                            {...items.filters[5]}
                            key={items.filters[5].slug}
                            cat="price"
                            price_min={items.filters[5].min}
                            price_max={items.filters[5].max}
                            setOpen={setOpen}
                            current={open}
                            handleRangeChange={handleRangeChange}
                          />
                          <Filter
                            {...items.filters[4]}
                            key={items.filters.slug}
                            cat="agence"
                            setOpen={setOpen}
                            current={open}
                            handleInputChange={handleInputChange}
                          />
                        </div>
                      )}

                      <Results
                        {...items}
                        isLast={isLast}
                        setIsLast={setIsLast}
                        newContent={newContent}
                        setNewContent={setNewContent}
                        next={next}
                        last={last}
                        filterLast={filterLast}
                        filterCurrent={filterCurrent}
                        offerParams={offerParams}
                      />
                    </div>
                  </div>
                  <div className="right-col hidden-s hidden-m">
                    <Live />
                    {is_bloc_newsletter_subscription &&
                      global.bloc_newsletter && <Newsletter {...global} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SkeletonPage />
      )}
    </>
  );
});
