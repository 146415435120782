import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonPage = () => {
  let pageRef = useRef();

  // STORE REDUX
  let header = useSelector((state) => state.header);
  let { height_before_sticky } = header;

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.style.marginTop = `${height_before_sticky}px`;
    }
  }, [height_before_sticky]);

  return (
    <SkeletonTheme>
      <div className="page" ref={pageRef}>
        <div className="top-banner">
          <div className="container">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="content">
          <div className="container">
            <div className="home">
              <Skeleton height={200} />
              <div className="main-wrapper">
                <div className="hidden-s hidden-m">
                  <Skeleton height={500} />
                  <Skeleton height={300} />
                  <Skeleton height={300} />
                </div>
                <div className="main-content">
                  <main>
                    <Skeleton height={300} />
                  </main>

                  <div className="hidden-l hidden-xl">
                    <div className="row">
                      <Skeleton height={300} />
                      <div className="hidden-s">
                        <Skeleton height={200} />

                        <Skeleton height={300} />
                      </div>
                    </div>
                  </div>

                  <main className="row">
                    <Skeleton height={200} />
                    <Skeleton height={200} />
                    <div className="hidden-m hidden-l hidden-xl">
                      <Skeleton height={300} />
                    </div>
                  </main>

                  <Skeleton height={100} />

                  <main className="row">
                    <Skeleton height={200} />
                    <Skeleton height={200} />
                  </main>

                  <Skeleton height={50} />

                  <main className="hidden-m hidden-l hidden-xl">
                    <div className="row">
                      <Skeleton height={200} />
                      <Skeleton height={200} />
                    </div>
                  </main>

                  <div className="hidden-l hidden-xl">
                    <div className="row change-order">
                      <Skeleton height={200} />
                      <Skeleton height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-col hidden-s hidden-m">
                <>
                  <Skeleton height={500} />

                  <Skeleton height={300} />
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
