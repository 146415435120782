import React, { useState, useEffect, useRef } from "react";
import { generate } from "shortid";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

export const SocialNetworks = React.memo((props) => {
  let { links } = props;
  const [showInput, setShowInput] = useState(false);
  let { ga } = useSelector((state) => state.global);
  const inputRef = useRef(null);

  const onClickEvent = (item) => {
    if (ga) {
      ReactGA.event({
        category: item.text,
        action: "Click",
        label: item.text,
      });
    }
    if (item.text === "Rss") {
      setShowInput(!showInput);
    } else {
      setShowInput(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ul style={{ listStyleType: "none", padding: 0 }}>
      {links.map((item) => {
        return (
          <li key={generate()}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              <a
                href={item.url}
                target="_blank"
                title={item.text}
                rel="noopener noreferrer"
                onClick={() => onClickEvent(item)}
              >
                <i
                  className={`icon icon-${item.text.toLowerCase()}`}
                ></i>
              </a>
              {item.text === "Rss" && showInput && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "350px",
                    padding: "10px",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(255, 0, 0, 0.2)",
                    margin: "10px"
                  }}
                  ref={inputRef}
                >
                  <input
                    type="text"
                    defaultValue="https://apiv5.gips-cloud.com/api/feed"
                    style={{ width: "100%", marginBottom: "5px", marginTop: "5px", fontSize: "18px", fontStyle: "italic", color: "rgba(0, 0, 0, 0.7)" }}
                  />
                  <div
                    style={{ textAlign: "right", cursor: "pointer" }}
                    onClick={() => setShowInput(false)}
                  >
                  </div>
                </div>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
});