import React from "react";
import { Fade } from "react-awesome-reveal";

import { CreateComponentsByJSON } from "../../Utils/CreateComponentsByJSON";

export const Suggested = ({ suggested_news }) => {
  let { pushes = [], title } = suggested_news;

  return (
    <>
      {pushes.length > 0 ? (
        <Fade cascade direction="top" triggerOnce className="suggested">
          <h2 className="block-title">{title}</h2>
          <div className="row">
            {pushes.map((block) => {
              return CreateComponentsByJSON(block, true, true);
            })}
          </div>
        </Fade>
      ) : null}
    </>
  );
};
