import React from "react";
import { useSelector } from "react-redux";
/**
 * ## Share
 * Deux types de share : celle sur les articles, et l'autre sur les push;
 * @param {metas} object
 * ---
 * inspired by https://github.com/Financial-Times/o-share/blob/master/src/js/share.js#L155
 *
 */

// External lib
import ReactGA from "react-ga";

export const Share = (props) => {
  let url = "",
    title = "",
    summary = "",
    relatedTwitterAccounts = "",
    titleExtra = "";

  const TWITTER_COUNT = "GazettedeMonaco";
  const LINKEDIN_COUNT = "la-gazette-de-monaco";

  let { type } = props;

  switch (type) {
    case "article":
      url = encodeURIComponent(window.location.href.toString());
      title = encodeURIComponent(props.metas.title);
      summary = encodeURIComponent(props.metas.description);
      break;
    case "push":
      url = encodeURIComponent(
        process.env.REACT_APP_LNK + props.datas.title.link.url
      );
      title = encodeURIComponent(props.datas.title.text);
      summary = encodeURIComponent(props.datas.chapo);
      break;
    default:
      break;
  }

  return (
    <div className="share" data-share-type={type}>
      <div className="share_wrapper">
        <ShareButton
          name="twitter"
          url={`https://twitter.com/intent/tweet?url=${url}&text=${title}&related=${relatedTwitterAccounts}&via=${TWITTER_COUNT}`}
          title={title}
        />
        <ShareButton
          name="facebook"
          url={`http://www.facebook.com/sharer.php?u=${url}`}
          title={title}
        />
        <ShareButton
          name="linkedin"
          url={`http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}+%7C+${titleExtra}&summary=${summary}&source=${LINKEDIN_COUNT}`}
          title={title}
        />

        {/* {type === 'article' && (
					<ShareButton
						name="pinterest"
						url={`http://www.pinterest.com/pin/create/button/?url=${url}&description=${title}`}
						title={title}
					/>
				)} */}
      </div>
    </div>
  );
};

const ShareButton = ({ name, url, title }) => {
  let { ga } = useSelector((state) => state.global);

  const onClickEvent = () => {
    if (ga) {
      ReactGA.event({
        category: "Share",
        action: "Click",
        label: `${name} - ${title.replace(/%20/g, " ")}`,
      });
    }
  };

  return (
    <a
      href={url}
      className="share_button"
      target="_blank"
      title={name}
      rel="noopener noreferrer"
      onClick={onClickEvent}>
      <i className={`icon icon-${name}`} />
    </a>
  );
};
