import React, {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import {Fade} from 'react-awesome-reveal';

// Components
import {CreateComponentsByJSON} from '../Utils/CreateComponentsByJSON'
import {Metatags} from '../Components/Metatags';
import {Live} from '../Components/Pushes/Live';
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {SkeletonSingle} from '../Components/Skeletons/SkeletonSingle';
import {Newsletter} from '../Components/Newsletter';

// External lib
import ReactGA from 'react-ga';

// Hooks
import {useApi} from '../Hooks/useApi';

export const TemplateContenu = ({_uid}) => {
    let pageRef = useRef();
    let dispatch = useDispatch();
    let history = useHistory();
    let {pathname, hash} = useLocation();
    let url = pathname.split('/').pop();
    let {global, ga} = useSelector(state => state.global);
    let [dataContent, dataContentIsLoaded] = useApi({name: 'content-page', _uid});

    let isLoaded = dataContentIsLoaded;

    let {flexibles = [], metas, title, seo_link, is_bloc_newsletter_subscription} = dataContent;

    // GA
    const [microdatas, setMicrodatas] = useState({});
    useEffect(() => {
        if (isLoaded) {
        // if (isLoaded && ga) {
            // ReactGA.set({
            //     "@context": "https://schema.org",
            //     "@type": "ContactPage",
            //     "url": window.location.href
            // })
            setMicrodatas({
                "@context": "https://schema.org",
                "@type": "ContactPage",
                "url": window.location.href
            });
        }
    // }, [isLoaded, ga]);
    }, [isLoaded]);

    // Dispatch
    useEffect(() => {
        dispatch({type: "pageslug", value: 'contenu'});
    }, [isLoaded, dispatch])

    // replace url
    useEffect(() => {
        if (!url) history.replace({pathname: pathname.replace(/\/$/, "")})
    }, [hash, history, pathname, url])

    return (
        <>
            {isLoaded ?
                <>
                    <Metatags {...seo_link} {...metas} microdatas={microdatas}/>
                    <div className="page" ref={pageRef}>
                        <div className="container">
                            <div className="article">
                                <div className="main-wrapper">
                                    <Breadcrumbs {...dataContent} />
                                    <main>
                                        <div className="introduction">
                                            <h1 className="title">{title}</h1>
                                        </div>
                                        <div className="flexibles">
                                            <Fade triggerOnce cascade>
                                                {
                                                    flexibles.length > 0 ?
                                                        flexibles.map((block) => {
                                                            return CreateComponentsByJSON(block)
                                                        })
                                                        : null
                                                }
                                            </Fade>
                                        </div>
                                    </main>
                                </div>

                                <div className="right-col">
                                    <div className="hidden-s hidden-m">
                                        <Live/>
                                    </div>
                                    {(is_bloc_newsletter_subscription && global.bloc_newsletter) &&
                                    <Newsletter {...global} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : <SkeletonSingle/>
            }
        </>
    )
}