import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { GetTranslate } from '../Utils/getTranslate';
import { useLocation, useHistory } from 'react-router-dom';

// External comps
import { Metatags } from '../Components/Metatags';
import { Breadcrumbs } from '../Components/Breadcrumbs';
import { Button } from '../Components/Buttons';
import { SkeletonPage } from '../Components/Skeletons/SkeletonPage';

// hooks
import { useApi } from '../Hooks/useApi';
import { useTranslate } from '../Hooks/useTranslate';
import { useSelector } from 'react-redux';

export const Template404 = () => {
	let pageRef = useRef();
	let dispatch = useDispatch();
	let history = useHistory();
	let { pathname, hash } = useLocation();
	let url = pathname.split('/').pop();

	let { lang } = useSelector(state => state.lang);
	let [ data404, data404IsLoaded ] = useApi({name:'page404'});
	let isLoaded = data404IsLoaded;

	let { metas, seo_link } = data404;
	let backToStartWord = useTranslate('backToStart');

	// Dispatch
	useEffect(()=>{
		dispatch({type:"pageslug", value: '404'})
	},[dispatch, isLoaded])

	// replace url
	useEffect(() => {
		if (!url) history.replace({ pathname: pathname.replace(/\/$/, "") })
	}, [hash, history, pathname, url])

	return (
		<>
		{ isLoaded ? 
			<>
				<Metatags {...seo_link} {...metas} />
				<div className="page" ref={pageRef}>
					<div className="content">
						<div className="container">
							<div className="p404">
								<Breadcrumbs {...data404} />
								<main>
									<Module404 />
				
									<Button className="red" type="button" link={`${lang === 'fr' ? '/' : '/en/'}`}>{backToStartWord}</Button>
								</main>
							</div>
						</div>
					</div>
				</div>
			</>
			: <SkeletonPage />
		}
		</>
	);
};

const Module404 = () => {
	return (
		<div className="text">
			<p className="cat-title">
				<GetTranslate keyword="404error" />&nbsp;<span className="red"><GetTranslate keyword="404error2" /></span>
			</p>
			<p>
				<GetTranslate keyword="404message" />
			</p>
		</div>
	);
};
