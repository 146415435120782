import React from "react";

// Hooks
import { useTranslate } from "../Hooks/useTranslate";

export const Loader = () => {
  let [tradLoading] = useTranslate("loading");
  return (
    <div className="c-loader" data-skeleton="true">
      {tradLoading}
    </div>
  );
};

export const LoaderPage = ({ load }) => {
  return (
    <div className="c-loader-page" data-load={load}>
      <div></div>
      <div className="c-loader-page_center">
        <div className="c-loader-page_spinner">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
