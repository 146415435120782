import React, {useRef, useCallback, useLayoutEffect} from "react";
import {CreateComponentsByJSON} from '../../Utils/CreateComponentsByJSON'
import {Fade} from 'react-awesome-reveal';

// external comps
import {Loader} from '../Loader';

// hooks
import {useSelector} from 'react-redux';
import LiveLogo from '../../Assets/img/live.svg';

// Hooks
import {useApi} from '../../Hooks/useApi';

export const Live = (props) => {
    let [dataFetch, isLoaded] = useApi({name: 'live', _uid: props._uid});

    // Ref
    let liveWrapperRef = useRef();
    let liveScrollerRef = useRef();
    let liveTopScrollRef = useRef();
    let liveBottomScrollRef = useRef();

    let {pushes = [], title} = dataFetch;
    let color = "#FFFFFF";
  

    return (
        <AutoScroll
            className="live"
            pushes={pushes}
            title={title}
            color={color}
            isLoaded={isLoaded}
            logo={LiveLogo}
            wrapperRef={liveWrapperRef}
            scrollerRef={liveScrollerRef}
            topScrollRef={liveTopScrollRef}
            bottomScrollRef={liveBottomScrollRef}/>
    )
}

export const AutoScroll = (props) => {
    let {isLoaded, color, title, pushes, className, logo, wrapperRef, scrollerRef, topScrollRef, bottomScrollRef} = props;
    let color2 = 'rgba(255, 255, 255, 0)';

    // Store
    let breakpointStore = useSelector(state => state.breakpoint);

    // Destructuring
    let {mobile} = breakpointStore;

    // Arrows and gradients
    const handleScrolledElements = useCallback(() => {
        if (wrapperRef && wrapperRef.current && bottomScrollRef && bottomScrollRef.current && topScrollRef && topScrollRef.current && scrollerRef && scrollerRef.current) {
            let el = wrapperRef.current;
            let bottom = (wrapperRef.current.scrollTop === wrapperRef.current.scrollHeight - 300) || (wrapperRef.current.scrollTop >= wrapperRef.current.scrollHeight - 300);
            let nodes = [...el.childNodes];

            if (nodes.length > 0) {
                wrapperRef.current.scrollTop > 50 ? topScrollRef.current.style.opacity = 1 : topScrollRef.current.style.opacity = 0;

                if (bottom) {
                    bottomScrollRef.current.style.opacity = 0;
                    scrollerRef.current.style.opacity = 0;
                } else {
                    bottomScrollRef.current.style.opacity = 1;
                    scrollerRef.current.style.opacity = 1;
                }
            }

        } else {
            bottomScrollRef.current.style.opacity = 1;
            scrollerRef.current.style.opacity = 1;
        }
    }, [wrapperRef, scrollerRef, topScrollRef, bottomScrollRef])

    useLayoutEffect(() => {
        if (!mobile) {
            let el = wrapperRef.current;
            if (wrapperRef && el) {

                el.addEventListener('scroll', handleScrolledElements);

                return () => {
                    el.removeEventListener('scroll', handleScrolledElements);
                }
            }
        }
    }, [wrapperRef, topScrollRef, isLoaded, mobile, handleScrolledElements])

    return (
        <Fade triggerOnce cascade direction="top">
            <aside
                className={className}
                style={{backgroundColor: color}}>

                {isLoaded ? (
                    <>
                        <h2 className="block-title">{logo && <img src={logo} alt=""/>}{title}</h2>
                        {pushes.length > 0 ? (
                            <>
                                <div className="pushes-wrapper">
                                    <div className="pushes" ref={wrapperRef}>
                                        {
                                            pushes.map(block => CreateComponentsByJSON(block, false, false, false, title.toLowerCase()))
                                        }
                                    </div>

                                    <span className="top-gradient" ref={topScrollRef} style={{
                                        background: `linear-gradient(180deg, ${color} 0%, ${color2} 100%)`,
                                        opacity: 0
                                    }}></span>
                                    <span className="gradient" ref={bottomScrollRef}
                                          style={{background: `linear-gradient(0deg, ${color} 0%, ${color2} 100%)`, top: '1px'}}></span>
                                    <button className="scroller" ref={scrollerRef}
                                            onClick={() => wrapperRef.current.scrollBy({
                                                behavior: 'smooth',
                                                top: 200,
                                                left: 0
                                            })}><i className="icon icon-chevron-down"></i></button>

                                </div>
                            </>
                        ) : null}
                    </>
                ) : <Loader/>
                }
            </aside>
        </Fade>
    )
}