import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export const PriceFilter = ({
  title,
  cat,
  setOpen,
  price_min,
  price_max,
  current,
  displayed,
  handleRangeChange,
}) => {
  const { Range } = Slider;

  // select open state
  let [expandabled, setExpandabled] = useState(false);

  // select states
  let [state, setState] = useState({
    active: false,
    price_min: parseInt(price_min),
    price_max: parseInt(price_max),
  });

  const handleToggle = () => {
    current === cat ? setOpen(null) : setOpen(cat);
  };

  const handleChange = (e) => {
    setState({
      active: true,
      price_min: e[0],
      price_max: e[1],
    });
  };

  const handleSubmit = () => {
    handleRangeChange(state);
  };

  useEffect(() => {
    current === cat ? setExpandabled(true) : setExpandabled(false);
  }, [current, cat]);

  return (
    displayed && (
      <div
        className="select"
        data-list-open={expandabled}
        data-active={state.active}>
        <button className="select-btn" onClick={handleToggle} data-value="">
          <span className="txt">{title}</span>
          <span className={`${expandabled ? "icon-down" : "icon-up"}`}></span>
        </button>
        <div className="select-list">
          <div className="rangeWrapper">
            {state.price_min.toLocaleString()}€ <span className="sep">-</span>{" "}
            {state.price_max.toLocaleString()}€
          </div>
          <Range
            defaultValue={[0, price_max]}
            min={0}
            max={price_max}
            step="100"
            onAfterChange={handleSubmit}
            onChange={handleChange}
            pushable={true}
            allowCross={false}
          />
        </div>
      </div>
    )
  );
};
