import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// External lib
import ReactGA from "react-ga";

export const Logo = ({ logo, lang, page }) => {
  let refEvent = useRef(false);
  let { ga } = useSelector((state) => state.global);

  const handleClick = () => {
    if (page === "homepage") {
      if (!refEvent.current && ga) {
        ReactGA.event({
          category: "Organization",
          action: "Clicked Logo",
          label: "La Gazette de Monaco",
        });
        window.location.reload();
        refEvent.current = true;
      }
    }
  };

  const handleReplace = () => {
    lang == "fr" ? window.location.replace("/") : window.location.replace("/en/");
  };

  return (
    <>
      {page === "homepage" ? (
        <h1 className="header-logo">
          <Link
            to={`${lang === "fr" ? "/" : "/en/"}`}
            onClick={() => handleClick()}
          >
            <img src={logo.img_src} alt={logo.img_alt} />
          </Link>
        </h1>
      ) : (
        <span className="header-logo">
          <Link to={`${lang === "fr" ? "/" : "/en/"}` } >
            <img src={logo.img_src} alt={logo.img_alt} onClick={handleReplace}/>
          </Link>
        </span>
      )}
    </>
  );
};
