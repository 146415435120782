import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Fade } from "react-awesome-reveal";

// Components
import { CreateComponentsByJSON } from "../Utils/CreateComponentsByJSON";
import { Metatags } from "../Components/Metatags";
import { Live } from "../Components/Pushes/Live";
import { Immobilier } from "../Components/Pushes/Immobilier";
import { Introduction } from "../Components/Article/Introduction";
import { Suggested } from "../Components/Pushes/Suggested";
import { Banners } from "../Components/Banners";
import { Share } from "../Components/Article/Share";
import { Breadcrumbs } from "../Components/Breadcrumbs";
import { SkeletonSingle } from "../Components/Skeletons/SkeletonSingle";
import { Carousel23 } from "../Components/Article/CarouselImages";

// External lib
import ReactGA from "react-ga";

// Hooks
import { useApi } from "../Hooks/useApi";

export const TemplateArticle = () => {
  let pageRef = useRef();
  let dispatch = useDispatch();
  let history = useHistory();
  let { pathname, hash } = useLocation();
  let { global, ga } = useSelector((state) => state.global);
  let url = pathname.split("/").pop();
  let [dataBanners, dataBannersIsLoaded] = useState();
  let [dataArticle, dataArticleIsLoaded] = useApi({
    name: "news/single",
    slug: url,
  });
  const prevData = useRef({ dataBanners }).current;

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  let isLoaded = dataArticleIsLoaded;

  let {
    flexibles = [],
    metas,
    author,
    title,
    preview_image,
    date,
    seo_link,
    microdonnees,
  } = dataArticle;

  // Store path
  useEffect(() => {
    dataArticle.path_fr
      ? (window.pathArticle = dataArticle.path_fr)
      : (window.pathArticle = dataArticle.path_en);
  }, [dataArticle, isLoaded]);

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, [window.twttr]);

  // GA
  const [microdatas, setMicrodatas] = useState({});

  useEffect(() => {
    if (isLoaded) {
      // if (isLoaded && ga) {
      // ReactGA.set({
      //     "@type": "NewsArticle",
      //     "mainEntityOfPage": {
      //         "@type": "WebPage",
      //         "@id": microdonnees.article.url
      //     },
      //     "headline": microdonnees.article.title,
      //     "image": microdonnees.article.image_url,
      //     "datePublished": microdonnees.article.dateCreated,
      //     "dateModified": microdonnees.article.dateModified,
      //     "author": {
      //         "@type": "Person",
      //         "name": microdonnees.article.author
      //     },
      //     "publisher": {
      //         "@type": "Organization",
      //         "name": microdonnees.article.organization
      //     }
      // })

      setMicrodatas({
        // "@type": "NewsArticle",
        test: "teste",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": microdonnees.article.url,
        },
        headline: microdonnees.article.title,
        image: microdonnees.article.image_url,
        // Carousel microdonnees
        imageCarousel1: microdonnees.article.image_url_Carousel1,
        imageCarousel2: microdonnees.article.image_url_Carousel2,
        imageCarousel3: microdonnees.article.image_url_Carousel3,
        imageCarousel4: microdonnees.article.image_url_Carousel4,
        imageCarousel5: microdonnees.article.image_url_Carousel5,
        // Carousel microdonnees
        datePublished: microdonnees.article.dateCreated,
        dateModified: microdonnees.article.dateModified,
        author: {
          "@type": "Person",
          name: microdonnees.article.author,
        },
        publisher: {
          "@type": "Organization",
          name: microdonnees.article.organization,
        },
      });
    }
    // }, [isLoaded, ga]);
  }, [isLoaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFetch = await Axios.get(
          `${process.env.REACT_APP_APP}${
            global.banners.rubriques[dataArticle.categories[0].slug]
          }`,
          { cancelToken: source.token }
        );
        if (prevData.dataFetch !== dataFetch) {
          dataBannersIsLoaded(dataFetch.data);
        }
      } catch (err) {
        // console.log(err)
      }
    };

    fetchData();
    return () => {
      source.cancel();
      prevData.dataBanners = dataBanners;
    };
  }, [global.banners.rubriques, dataArticle.categories, prevData]);

  useEffect(() => {
    dispatch({ type: "pageslug", value: "article" });
  }, [isLoaded, dispatch]);

  // replace url
  useEffect(() => {
    if (!url) history.replace({ pathname: pathname.replace(/\/$/, "") });
  }, [hash, history, pathname, url]);

  var carouselSave = [];

  // Carousel function
  var carouselShow = () => {
    if (
      microdonnees.article.image_url_Carousel1 !==
        "https://uploadsv5.gips-cloud.com/" &&
      microdonnees.article.image_url_Carousel1 !==
        "https://uploadsv5.gips-cloud.com/logo-gazette-123497.gif"
    ) {
      carouselSave.push([microdonnees.article.image_url_Carousel1, microdonnees.article.copyright_imageCarou1]);
    }
    if (
      microdonnees.article.image_url_Carousel2 !==
        "https://uploadsv5.gips-cloud.com/" &&
      microdonnees.article.image_url_Carousel2 !==
        "https://uploadsv5.gips-cloud.com/logo-gazette-123497.gif"
    ) {
      carouselSave.push([microdonnees.article.image_url_Carousel2, microdonnees.article.copyright_imageCarou2]);
    }
    if (
      microdonnees.article.image_url_Carousel3 !==
        "https://uploadsv5.gips-cloud.com/" &&
      microdonnees.article.image_url_Carousel3 !==
        "https://uploadsv5.gips-cloud.com/logo-gazette-123497.gif"
    ) {
      carouselSave.push([microdonnees.article.image_url_Carousel3, microdonnees.article.copyright_imageCarou3]);
    }
    if (
      microdonnees.article.image_url_Carousel4 !==
        "https://uploadsv5.gips-cloud.com/" &&
      microdonnees.article.image_url_Carousel4 !==
        "https://uploadsv5.gips-cloud.com/logo-gazette-123497.gif"
    ) {
      carouselSave.push([microdonnees.article.image_url_Carousel4, microdonnees.article.copyright_imageCarou4]);
    }
    if (
      microdonnees.article.image_url_Carousel5 !==
        "https://uploadsv5.gips-cloud.com/" &&
      microdonnees.article.image_url_Carousel5 !==
        "https://uploadsv5.gips-cloud.com/logo-gazette-123497.gif"
    ) {
      carouselSave.push([microdonnees.article.image_url_Carousel5, microdonnees.article.copyright_imageCarou5]);
    }
    // console.log("carouselSave", carouselSave);
    if (carouselSave.length > 0) {
      return <Carousel23 {...carouselSave} />;
    } else {
      return <div></div>;
    }
  };
  // Carousel function

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...seo_link} {...metas} microdatas={microdatas} />
          <div className="page" ref={pageRef}>
            <Banners {...dataBanners} name="billboard" />

            <div className="container">
              <div className="article">
                <div className="main-wrapper">
                  <Breadcrumbs {...dataArticle} />
                  <main>
                    <Introduction {...dataArticle} />
                    <div className="flexibles">
                      <Fade triggerOnce cascade bottom>
                        {flexibles.length > 0 &&
                          flexibles
                            .slice(0, 1)
                            .map((block) => CreateComponentsByJSON(block))}
                      </Fade>
                      <div className="hidden-l hidden-xl">
                        <Banners {...dataBanners} name="right_halfpage" />
                      </div>
                      <Fade triggerOnce cascade bottom>
                        {flexibles.length > 1 &&
                          flexibles
                            .slice(1, Infinity)
                            .map((block) => CreateComponentsByJSON(block))}
                      </Fade>
                    </div>

                    {author ? (
                      <strong className="author">{author}</strong>
                    ) : null}

                    {/* Carousel function call */}
                    {carouselShow()}

                    {/* Carousel function call */}
                    <br></br>
                    <br></br>

                    <Share {...dataArticle} type="article" />
                  </main>

                  <aside className="article-bottom">
                    <Banners {...dataBanners} name="left_halfpage" />

                    <div className="hidden-m hidden-l hidden-xl">
                      <Immobilier />
                    </div>
                    <Suggested {...dataArticle} />
                  </aside>
                </div>

                <div className="right-col hidden-s hidden-m">
                  <Live />

                  <Banners {...dataBanners} name="right_halfpage" />

                  <Immobilier />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SkeletonSingle />
      )}
    </>
  );
};
